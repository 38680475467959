import Vue from 'vue'
import Vuex from 'vuex'
import {user} from './modules/user.js'
import {role} from './modules/role.js'
import {permission} from './modules/permission.js'
import {obra} from './modules/obra.js'
import {frente_obra} from './modules/frente_obra.js'
import {funcionario} from './modules/funcionario.js'
import {entidade_pessoal} from './modules/entidade_pessoal.js'
import {sub_familia} from './modules/sub_familia.js'
import {sub_empreitada} from './modules/sub_empreitada.js'
import {pdeq} from './modules/pdeq.js'
import {pdenc} from './modules/pdenc.js'
import {pdmo} from './modules/pdmo.js'
import {approval} from './modules/approval.js'
import {classe} from './modules/classe.js'
import {equipamentos} from './modules/equipamentos.js'
import {materiais} from './modules/materiais.js'
import {unidades} from './modules/unidades.js'
import {alerts} from './modules/alerts.js'
import {notifications} from './modules/notifications.js'
import {statistics} from './modules/statistics.js'
import {logs} from './modules/logs.js'
import {testings} from './modules/testings.js'
import {odc_machines} from './modules/odc_machines.js'
import { external_communications } from './modules/external_communications.js'
import { work_schedule } from './modules/work_schedule.js'
import { worker_cards } from './modules/worker_cards.js'
import { exportation } from './modules/exportation.js'
import { templates } from './modules/templates.js'
import { works } from './modules/works.js'
import {tickets} from './modules/tickets.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    previousRoute: "/"
  },
  getters: {
  },
  mutations: {
    SET_PREVIOUS_ROUTE(state, route) {
      let routesToAdmin = [
        "/",
        "/admin",
        "/login"
      ];

      state.previousRoute = routesToAdmin.indexOf(route) > -1 ? "/admin" : route;
    }
  },
  actions: {
    setPreviousRoute({commit}, route) {
      commit("SET_PREVIOUS_ROUTE", route.path);
    }
  },
  modules: {
    odc_machines: odc_machines,
    external_communications: external_communications,
    user: user,
    role: role,
    permission:permission,
    obra: obra,
    frente_obra: frente_obra,
    pdeq: pdeq,
    funcionario: funcionario,
    entidade_pessoal:entidade_pessoal,
    sub_familia:sub_familia,
    sub_empreitada: sub_empreitada,
    pdenc: pdenc,
    pdmo: pdmo,
    approval:approval,
    classe:classe,
    equipamentos:equipamentos,
    materiais: materiais,
    unidades: unidades,
    alerts: alerts,
    notifications: notifications,
    statistics: statistics,
    logs: logs,
    testings: testings,
    work_schedule: work_schedule,
    worker_cards: worker_cards,
    exportation: exportation,
    templates: templates,
    works: works,
    tickets: tickets
  }
})
