<template>
    <section class="mt-16">
      <v-row class="d-none d-lg-block mx-3 px-3">
            <v-col cols="12">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                  ></v-breadcrumbs>
  
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo Diário Manobrador</span>
                    </h1>
  
                    <div class="mt-16">
                      <Operator method="create"></Operator>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-block d-lg-none">
            <v-col cols="12">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                  ></v-breadcrumbs>
  
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo Diário Manobrador</span>
                    </h1>
  
                    <div class="mt-16">
                      <Operator method="create"></Operator>
                </div>
            </v-col>
        </v-row>
    </section>
  </template>
  <script>
  import Operator from '@/components/Diaries/Operator/Operator.vue'
  
  export default {
    components: {
        Operator
    },
    data: () => ({
      
    }),
    mounted(){
  
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
            {
                text: 'Registo Diário Manobrador',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
      }
    }
  };
  </script>
  