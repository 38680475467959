<template>
    <v-card>
        <v-card-title class="primary white--text">
            Detalhes de obra
        </v-card-title>
        <v-progress-linear
            v-if="loading"
            color="red"
            indeterminate
        ></v-progress-linear>
        <v-divider></v-divider>
        <v-tabs
            v-model="tab"
            dark
            fixed-tabs
            background-color="primary"
            icons-and-text                            
        >
            <v-tab :key="0" v-if="$store.getters.hasPermission(['super', 'work_consult.read_workers'])">
                Manobradores
                <v-icon>mdi-steering</v-icon>
            </v-tab>
            <v-tab :key="1" v-if="$store.getters.hasPermission(['super', 'work_consult.read_workers'])">
                Mão Obra
                <v-icon>mdi-account-hard-hat</v-icon>
            </v-tab>
            <v-tab :key="2" v-if="$store.getters.hasPermission(['super', 'work_consult.read_equipments'])">
                Equipamento
                <v-icon>mdi-bulldozer</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-if="$store.getters.hasPermission(['super', 'work_consult.read_workers'])">
                <v-card-text>
                    <v-row class="mt-4">
                        <v-col cols="12" md="6">
                            <v-card-title class="warning white--text">
                                Manobradores
                            </v-card-title>
                            <v-card class="elevation-0" outlined height="55vh" style="overflow-y: scroll">                        
                                <v-card-text class="mt-4">
                                    <v-row v-for="item in manobradores" :key="item.Codigo">
                                        <v-col cols="12" md="5">
                                            {{item.Codigo}} - {{item.Nome}}
                                        </v-col>
                                        <v-col cols="12" md="7">
                                            {{arrayComma(item.equipamentos)}}
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-title class="warning white--text">
                                Manobradores - ODC
                            </v-card-title>
                            <v-card class="elevation-0" outlined height="55vh" style="overflow-y: scroll">                        
                                <v-card-text class="mt-4">
                                    <v-row v-for="item in manobradores_odc" :key="item.id">
                                        <v-col cols="12" md="5">
                                            {{item.user.funcionario_codigo}} - {{item.user.name}}
                                        </v-col>
                                        <v-col cols="12" md="7">
                                            {{arrayComma(item.equipments)}}
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-tab-item>
            <v-tab-item  v-if="$store.getters.hasPermission(['super', 'work_consult.read_workers'])">
                <v-card-text>
                    <v-row class="mt-4">
                        <v-col cols="12" md="6">
                            <v-card-title class="warning white--text">
                                Funcionários internos
                            </v-card-title>
                            <v-card class="elevation-0" outlined height="35vh" style="overflow-y: scroll">                        
                                <v-card-text class="mt-4">
                                    <v-row>
                                        <v-col cols="12" v-for="item in funcionarios_internos">
                                            {{item.Codigo}} - {{item.Nome}}
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card-title class="warning white--text">
                                Funcionários externos
                            </v-card-title>
                            <v-card class="elevation-0" outlined height="35vh" style="overflow-y: scroll">   
                                <v-card-text class="mt-4">
                                    <v-row>
                                        <v-col cols="12" v-for="item in funcionarios_externos">
                                            {{item.Numero}} - {{item.Nome}}
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-tab-item>
            <v-tab-item v-if="$store.getters.hasPermission(['super', 'work_consult.read_equipments'])">
                <v-card-text>           
                    <v-row class="mt-4">
                        <v-col cols="12" md="6">
                            <v-card-title class="warning white--text">
                                Equipamentos internos
                            </v-card-title>
                            <v-card class="elevation-0" outlined height="35vh" style="overflow-y: scroll">   
                                <v-card-text class="mt-4">
                                    <v-row>
                                        <v-col cols="12" v-for="item in equipamentos_internos">
                                            {{item.EntityKey}} - {{item.Descricao}}
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card-title class="warning white--text">
                                Equipamentos externos
                            </v-card-title>
                            <v-card class="elevation-0" outlined height="35vh" style="overflow-y: scroll">
                                <v-card-text class="mt-4">
                                    <v-row>
                                        <v-col cols="12" v-for="item in equipamentos_externos">
                                            {{item.EntityKey}} - {{item.Descricao}}
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>
<script>
import Obra from "@/api/Obra.js"
import Work from "@/api/Works.js"

export default{
    name:"WorkDetails",
    props:{
        selectedWork: {
            typeof: Object, 
            default: null
        }
    },
    data(){
        return{
            tab: 0,
            loading: true,
            funcionarios_internos: [],
            funcionarios_externos: [],
            equipamentos_externos: [],
            equipamentos_internos: [],
            manobradores: [],
            manobradores_odc: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        arrayComma(ar){
            
            let ret = []
            ar.forEach(element => {
                ret.push(element.EntityKey + ' - ' + element.Descricao);
            });
            return ret.toString()
        },
        fillBaseData(){
            let data = {
                id: this.selectedWork.Id,
                frente: null
            }
            Obra.getObraFuncionarios(data).then((resp)  =>  {
                this.funcionarios_internos = resp.data
            })

            Obra.getObraPessoal(this.selectedWork.Id).then((resp)    =>  {
                this.funcionarios_externos = resp.data
            })

            data = {
                work_id: this.selectedWork.Id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'encarregado'
            }

            Obra.getObraEquipamentos(data).then((resp)  =>  {
                this.equipamentos_externos = resp.data.externo
                this.equipamentos_internos = resp.data.interno
                this.loading = false
            })

            Obra.getManobradoresDeObra(this.selectedWork.Id).then((resp)    =>  {
                this.manobradores = resp.data
            })

            Work.getMachinesByUser(this.selectedWork.Id).then((resp)        =>  {
                this.manobradores_odc = resp.data
            })

            this.loading = false
        }
    }
}
</script>