<template>
    <v-card>        
        <v-card-title class="primary white--text">Adicionar colaboradores: {{selectedWork.Descricao}}</v-card-title>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <v-card-text class="mt-6" v-if="loading == false">
            <v-row>
                <v-col cols="12" md="8">
                    <v-autocomplete
                        :items="arrayWorkers"
                        :item-text="item => item.funcionario_codigo + ' - ' + item.name"
                        v-model="selectedWorker"
                        label="Utilizadores"
                        dense outlined
                        item-value="id"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-btn class="success" @click="addUsersToWork">
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Código
                                    </th>
                                    <th class="text-left">
                                        Nome
                                    </th>
                                    <th class="text-left">
                                        Role
                                    </th>
                                    <th class="text-left">
                                        Opção
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in workersInWork"
                                :key="item.id"
                                >
                                <td>{{ item.funcionario_codigo }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.roles }}</td>
                                <td>
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteUser(item)"
                                    />                                    
                                </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
        <DialogSuccess :opened="success.show" @on-ok="success.show = false" >
          {{success.msg}}
      </DialogSuccess>
    </v-card>
</template>
<script>
import User from "@/api/User.js"
import Works from "@/api/Works.js"
import IconRemove from '@/components/UI/IconRemove.vue';
import DialogSuccess from '@/components/UI/DialogSuccess.vue';

export default{
    name:"AddWorkers",
    props:{
        selectedWork: Object,
    },
    components:{
        IconRemove,
        DialogSuccess
    },
    data(){
        return{
            loading: true,
            workers: [],
            selectedWorker: null,
            workersInWork: [],
            success: {
                show: false,
                msg: ''
            }
        }
    },
    mounted(){
        console.log(this.selectedWork)
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            this.loadTable();  
            
            User.all().then((resp)  =>  {
                this.workers = resp.data

            })

            this.loading = false
        },
        async loadTable(){
            await Works.getUsers(this.selectedWork.Id).then((resp) => {
                
                this.workersInWork = resp.data

                this.loading = false
            })
        },
        addUsersToWork(){
            this.loading = true
            let data = {
                work_id: this.selectedWork.Id,
                user_id: this.selectedWorker
            }
            Works.addUser(this.selectedWork.Id, data).then((resp)   =>  {
                this.loadTable();
                this.success.msg = 'Adicionado com sucesso'
                this.success.show = true
                this.loading = false
            })
        },
        deleteUser(item){
            this.loading = true
            let data = {
                work_id: this.selectedWork.Id,
                user_id: item.id
            }
            Works.deleteUser(this.selectedWork.Id, data).then((resp)    =>  {
                this.loadTable();
                this.success.msg = 'Eliminado com sucesso'
                this.success.show = true
            })
        }
    },
    computed:{
        arrayWorkers(){
            let ar = []

            this.workers.forEach(worker => {
                let existe = false
                this.workersInWork.forEach(workerInWork => {
                    if(worker.funcionario_codigo == workerInWork.funcionario_codigo)
                        existe = true
                });

                if(existe == false)
                    ar.push(worker)
            });

            return ar
        }
    }
}
</script>