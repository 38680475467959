<template>
    <v-container fluid>
        <v-row v-if="!$store.getters.hasRole(['parque'])">
            <v-col cols="12">
                <v-toolbar
                    dark
                    color="warning"
                    class="rounded-lg"
                    >
                    <v-toolbar-title class="d-none d-lg-block">Observações de Mão de Obra:</v-toolbar-title>
                    <v-toolbar-title class="d-block d-lg-none text-caption">Observações de Mão de Obra:</v-toolbar-title>
                </v-toolbar>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="fields.obsMO"
                    outlined dense
                    label="Observações"
                    hint="Escreva as suas observações relativas à mão de obra"
                    persistent-hint
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-toolbar
                    dark
                    color="warning"
                    class="rounded-lg"
                    >
                    <v-toolbar-title class="d-none d-lg-block">Observações de Equipamentos:</v-toolbar-title>
                    <v-toolbar-title class="d-block d-lg-none text-caption">Observações de Mão de Obra:</v-toolbar-title>
                </v-toolbar>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="fields.obsEQ"
                    outlined dense
                    label="Observações"
                    hint="Escreva as suas observações relativas aos equipamentos"
                    persistent-hint
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default{
    name: "Observations",
    props:{
        fields: Object
    }
}
</script>