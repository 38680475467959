<template>
    <v-container fluid>
        <h2 class="primary--text">Gestão de Obras</h2>
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>
        <v-divider ></v-divider>
        <v-card>
            <v-tabs
                v-model="tab"
                background-color="primary"
                color="basil"
                dark
                slider-color="warning"
                grow
                >
                <v-tab
                    :key="0"
                    v-if="$store.getters.hasPermission(['super', 'work_consult.read'])"
                >
                    Obras
                </v-tab>
                <v-tab
                    :key="1"
                    v-if="$store.getters.hasPermission(['super', 'work_ccost.read'])"
                >
                    Centros de Custo
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-if="$store.getters.hasPermission(['super', 'work_consult.read'])"
                    :key="0"
                >
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="mx-4 px-4">

                            <v-data-table      
                                :headers="headers"
                                :loading="loading"
                                :items="items"
                                :server-items-length="total"
                                locale="pt-pt"        
                                :footer-props="tableFooter"
                                :options.sync="options"
                                class="elevation-0 mt-4"
                            >
                            <template v-slot:item.subempreitadas="{ item }">
                                <v-btn text :disabled="item.subempreitadas.length <= 0" @click="viewWork(item)">
                                    <v-icon>mdi-sigma</v-icon> {{item.subempreitadas.length}}
                                </v-btn>
                            </template>
                            <template v-slot:item.frentes="{ item }">
                                <v-btn text :disabled="item.obras.length <= 0" @click="viewObrasList(item)">
                                    <v-icon>mdi-sigma</v-icon> {{item.obras.length}}
                                </v-btn>
                            </template>
                            <template v-slot:item.unidades="{ item }">
                                <v-btn text :disabled="item.unidades.length <= 0" @click="viewUnidadeObra(item)">
                                    <v-icon>mdi-sigma</v-icon> {{item.unidades.length}}
                                </v-btn>
                            </template>

                            <template v-slot:item.level_approve="{item}">
                                <v-btn v-if="$store.getters.hasPermission(['super', 'works.update'])" fab x-small :color="item.approval_level == 2 ? 'primary' : ''" @click="updateApprovalLevel(item, 2)">
                                    <v-icon>mdi-numeric-2</v-icon>
                                </v-btn>
                                <v-btn v-if="$store.getters.hasPermission(['super', 'works.update'])" fab x-small :color="item.approval_level == 3 ? 'primary' : ''" @click="updateApprovalLevel(item, 3)">
                                    <v-icon>mdi-numeric-3</v-icon>
                                </v-btn>
                                <span v-if="!$store.getters.hasPermission(['super', 'works.update'])">Sem permissões</span>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-row>
                                    <v-col cols="12" md="auto" v-if="$store.getters.hasPermission(['super', 'work_consult.edit_workers'])">     
                                        <v-btn icon @click="addWorkers(item)">
                                            <v-icon>mdi-cog</v-icon>
                                        </v-btn>   
                                    </v-col>
                                    <v-col cols="12" md="auto" v-if="$store.getters.hasPermission(['super', 'work_consult.edit_equipments'])">     
                                        <v-btn icon @click="addMachines(item)">
                                            <v-icon color="warning">mdi-bulldozer</v-icon>
                                        </v-btn>  
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <v-btn icon @click="selectedWork = item; work_details = true">
                                            <v-icon color="red">mdi-list-box</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="auto" v-if="false">
                                        <v-btn icon @click="$router.push('/works_consult/kpi-config/' + item.Id)">
                                            <v-icon color="success">mdi-monitor-dashboard</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>       
                            </template>
                            
                            </v-data-table>        
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item
                    v-if="$store.getters.hasPermission(['super', 'work_ccost.read'])"
                    :key="1"
                >
                <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="mx-4 px-4">

                            <v-data-table      
                                :headers="headers"
                                :loading="loading"
                                :items="items"
                                :server-items-length="total"
                                locale="pt-pt"        
                                :footer-props="tableFooter"
                                :options.sync="options"
                                class="elevation-0 mt-4"
                            >
                            <template v-slot:item.subempreitadas="{ item }">
                                <v-btn text :disabled="item.subempreitadas.length <= 0" @click="viewWork(item)">
                                    <v-icon>mdi-sigma</v-icon> {{item.subempreitadas.length}}
                                </v-btn>
                            </template>
                            <template v-slot:item.frentes="{ item }">
                                <v-btn text :disabled="item.obras.length <= 0" @click="viewObrasList(item)">
                                    <v-icon>mdi-sigma</v-icon> {{item.obras.length}}
                                </v-btn>
                            </template>
                            <template v-slot:item.unidades="{ item }">
                                <v-btn text :disabled="item.unidades.length <= 0" @click="viewUnidadeObra(item)">
                                    <v-icon>mdi-sigma</v-icon> {{item.unidades.length}}
                                </v-btn>
                            </template>

                            <template v-slot:item.level_approve="{item}">
                                <v-btn v-if="$store.getters.hasPermission(['super', 'works.update'])" fab x-small :color="item.approval_level == 2 ? 'primary' : ''" @click="updateApprovalLevel(item, 2)">
                                    <v-icon>mdi-numeric-2</v-icon>
                                </v-btn>
                                <v-btn v-if="$store.getters.hasPermission(['super', 'works.update'])" fab x-small :color="item.approval_level == 3 ? 'primary' : ''" @click="updateApprovalLevel(item, 3)">
                                    <v-icon>mdi-numeric-3</v-icon>
                                </v-btn>
                                <span v-if="!$store.getters.hasPermission(['super', 'works.update'])">Sem permissões</span>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-row>
                                    <v-col cols="12" md="auto" v-if="$store.getters.hasPermission(['super', 'work_consult.edit_workers'])">     
                                        <v-btn icon @click="addWorkers(item)">
                                            <v-icon>mdi-cog</v-icon>
                                        </v-btn>   
                                    </v-col>
                                    <v-col cols="12" md="auto" v-if="$store.getters.hasPermission(['super', 'work_consult.edit_equipments'])">     
                                        <v-btn icon @click="addMachines(item)">
                                            <v-icon color="warning">mdi-bulldozer</v-icon>
                                        </v-btn>  
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <v-btn @click="selectedWork = item; work_details = true">
                                            <v-icon color="red">mdi-list-box</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>       
                            </template>
                            
                            </v-data-table>        
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <v-dialog
            v-model="dialog"
            width="850"
            >
                <Subempreitadas :selectedWork="selectedWork"/>
        </v-dialog>

        <v-dialog
            v-model="dialogFrentesList"
            width="850"
        >
            <Frentes :selectedWork="selectedWork" />
        </v-dialog>

        <v-dialog
            v-model="dialogUnidadesObra"
            width="850"
            >
            <Unidades :selectedWork="selectedWork" />
        </v-dialog>

            <v-dialog
                v-model="addWorkersToWork"
                width="850">
                <AddWorkers v-if="selectedWork != null" :selectedWork="selectedWork" />
            </v-dialog>



            <v-dialog
                v-model="addMachinesToWork"
                width="850">
                    <AddMachines v-if="selectedWork != null && addMachinesToWork" :selectedWork="selectedWork" />
            </v-dialog>

            <v-dialog
                v-model="work_details"
                width="85%">
                <WorkDetails v-if="work_details" :selectedWork="selectedWork"/>
            </v-dialog>

        <DialogConfirmation 
            v-if="askForConfirmation"
            :title="'Remover registo'" 
            type="warning" 
            :opened="askForConfirmation" 
            :elevation="0"
            @on-submit="removeWorker" 
            @on-cancel="askForConfirmation = false">
                Deseja remover o colaborador desta obra?
        </DialogConfirmation> 

        <DialogConfirmation 
            v-if="askForConfirmationMachine"
            :title="'Remover registo'" 
            type="warning" 
            :opened="askForConfirmationMachine" 
            :elevation="0"
            @on-submit="removeMachine" 
            @on-cancel="askForConfirmationMachine = false">
                Deseja remover a maquina desta obra?
        </DialogConfirmation> 

        <DialogConfirmation 
            v-if="item_to_approve.show_window"
            title="Alterar nível de aprovação" 
            type="warning" 
            :opened="item_to_approve.show_window" 
            :elevation="0"
            @on-submit="confirmUpdateApprovalLevel" 
            @on-cancel="item_to_approve.item = null; item_to_approve.show_window = false;">
                Tem a certeza que deseja alterar o nível de aprovação?
        </DialogConfirmation>   

        

    <SuccessSnackbar message="Sucesso na atribuição de obras" v-model="success"/>

    </v-container>
</template>

<script>
import Tooltip from "@/components/UI/Tooltips/Tooltip"

import PaginatedTable from "@/components/UI/Tables/PaginatedTable"
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";

import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';

import AddWorkers from "@/views/Works/AddWorkers.vue"
import AddMachines from "@/views/Works/AddMachines.vue"

import Subempreitadas from "@/views/Works/Dialogs/Subempreitadas.vue"
import Frentes from "@/views/Works/Dialogs/Frentes.vue"
import Unidades from "@/views/Works/Dialogs/Unidades.vue"

import WorkDetails from "@/views/Works/Dialogs/WorkDetails.vue"

export default {
    components: {
        Tooltip,
        PaginatedTable,
        SelectAutoComplete,
        SuccessSnackbar,
        DialogConfirmation,
        AddWorkers,
        AddMachines,
        Subempreitadas,
        Frentes,
        Unidades,
        WorkDetails
    },
    watch: {
        'tab':{
            immediate: true,
            handler(){
                setTimeout(() => {this.searchWorks()}, 10);
            }
        },
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.searchWorks()}, 10);
        },
        deep: true,
        },
    },
    data: () => ({
        tab: 0,
        work_details: false,
        item_to_approve:{
            item: null,
            level: null,
            show_window: false
        },
        dialogFrentesList: false,
        dialogUnidadesObra: false,
        askForConfirmation: false,
        removeWorkerObject: null,
        askForConfirmationMachine: false,
        workers: [],
        machines: [],
        selectedWork: null,
        selectedWorker: null,
        addWorkersToWork: false,
        addMachinesToWork: false,
        addMachinesFields:{
            equipment_id: null,
            work_id: null,
            user_id: null
        },
        headers: [
            {
                text: 'Código',
                sortable: false,
                value: 'Codigo',
            },
            {
                text: 'Obra',
                sortable: false,
                value: 'Descricao',
            },
            {
                text: 'Tipo',
                sortable: false,
                value: 'Tipo',
            },
            {
                text: 'Subempreitadas',
                sortable: false,
                value: 'subempreitadas',
            },
            {
                text: 'Frentes de Obra',
                sortable: false,
                value: 'frentes',
            },
            {
                text: 'Unidades de Obra',
                sortable: false,
                value: 'unidades',
            },
            {
                text: 'Aprovação',
                sortable: false,
                value: 'level_approve'
            },
            { 
                text: 'Opções', 
                value: 'actions', 
                sortable: false 
            },
        ],
        loading: false,
        success: false,
        options: {},
        items: [],
        total: 0,
        tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
        },
        filter: {
            name: null,
            email:null
        },
        selectedWork: null,
        dialog: false
    }),
    mounted(){
        this.fillBaseData()
    },
    methods: {
        confirmUpdateApprovalLevel(){
            let item = this.item_to_approve.item
            let level = this.item_to_approve.level
            let data = {
                id: item.Id,
                approval_level: level
            }

            this.$store.dispatch("changeApprovalLevel", data)
            .then((res) => {

                this.searchWorks()
                this.item_to_approve.item = null
                this.item_to_approve.level = null
                this.item_to_approve.show_window = false

            }).catch(err => {

                console.log(err)
            });
        },
        updateApprovalLevel(item, level){
            this.item_to_approve.item = item
            this.item_to_approve.level = level
            this.item_to_approve.show_window = true
        },
        addEquipmentWork(){
            this.addMachinesFields.work_id = this.selectedWork.Id
            this.addMachinesFields.equipment_id = this.addMachinesFields.equipment_id.Id
            this.addMachinesFields.user_id = this.addMachinesFields.user_id.id
            this.$store.dispatch("createEquipamentoObra", this.addMachinesFields)
                .then((res) => {
                
                    this.selectedWork = res.data.object
                    this.success = true
                }).catch(err => {

                    console.log(err)
                });
        },
        removeWorker(){
            let pos = -1
            let i = 0;
            this.selectedWork.workers.forEach(element => {
                if(element.id == this.removeWorkerObject.id)
                    pos = i

                i++;
            });

            if(pos != -1){
                this.selectedWork.workers.splice(pos, 1)
                let data = {
                    workers: this.selectedWork.workers,
                    id: this.selectedWork.Id
                }

                this.$store.dispatch("updateWorks", data)
                .then((res) => {
                    
                    this.selectedWork.workers = data.workers
                    this.askForConfirmation = false
                    this.success = true

                }).catch(err => {

                    console.log(err)
                });
            }
        },
        removeMachine(){
            let pos = -1
            let i = 0;

            this.selectedWork.freeEquipments.forEach(element => {
                if(element.equipment_id == this.removeMachineObject.equipment_id)
                    pos = i;

                i++;
            });

            if(pos != -1){
                this.selectedWork.freeEquipments.splice(pos, 1)
                let data = {
                    work_id: this.selectedWork.Id,
                    equipment_id: this.removeMachineObject.equipment_id,
                    user_id: this.removeMachineObject.user_id
                }

                this.$store.dispatch("removeEquipamentoObra", data)
                .then((res) => {
                    
                    this.selectedWork = res.data.object
                    this.askForConfirmationMachine = false
                    this.success = true
                    
                }).catch(err => {

                    console.log(err)
                });
            }
        },
        fillBaseData(){
            if(localStorage["odc-works-listings-" + this.$store.state.user.data.id] != undefined){

                let data = JSON.parse(localStorage["odc-works-listings-" + this.$store.state.user.data.id]);

                this.filter = data.filter;

                this.options = data.options;
            }
            
            /*this.$store.dispatch("getAllUsers")
            .then((res) => {
                
                this.workers = res.data

            }).catch(err => {

                console.log(err)
            });

            this.$store.dispatch("listEquipamentosUnassigned")
            .then((res) => {
                
                this.machines = res.data
                console.log(this.machines)

            }).catch(err => {

                console.log(err)
            });*/
        },
        addWorkers(item){
            this.selectedWork = item
            this.addWorkersToWork = true
        },
        addMachines(item){
            this.selectedWork = item
            this.addMachinesToWork = true
        },
        viewWork(work){
            this.selectedWork = work;
            this.dialog = true
        },
        viewObrasList(obra){
            this.selectedWork = obra;
            this.dialogFrentesList = true
        },
        viewUnidadeObra(unidade){
            this.selectedWork = unidade
            this.dialogUnidadesObra = true
        },
        searchWorks(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }
            

            this.loading = true;

            let filter = {...this.filter};
            
            let request = {
                sortBy:     this.options.sortBy,
                sortDesc:   this.options.sortDesc,
                page:       this.options.page,
                limit:      this.options.itemsPerPage,
                type:       this.tab == 0 ? 'O' : 'CC'
            };
            
            filter.work_father = true

            Object.assign(request, filter);

            localStorage["odc-works-listings-" + this.$store.state.user.data.id] = JSON.stringify({
                filter: filter,
                options: this.options,
            });

            this.$store.dispatch("searchWorks", request)
                .then(response => {

                    let items = response.data;
                    
                    this.total = items.total;

                    this.items = items.data;

                    this.loading = false;

            });
        },
        viewRole(work){
            this.$router.push("/super/works/" + work.id);
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Gestão de Obras',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>

</style>