import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import MFA from '../views/MFA.vue'
import Profile from '../views/Profile.vue'
import Page404 from '../views/Page404.vue'
import Page500 from '../views/Page500.vue'
import Reset from '../views/Reset.vue'
import Verify from '../views/Verify.vue'
import VerifyMicrosoft from '../views/VerifyMicrosoft.vue'
import {default as DiariesV2} from '../views/Diaries/DiariesV2.vue'
import {default as Diaries} from '../views/Diaries/Diaries.vue'
import {default as OperatorCreate} from '../views/Diaries/Operator/Create.vue'

import {default as OperatorEdit} from '../views/Diaries/Operator/Edit.vue'

import {default as OperatorEditEquipamentos} from '../views/Diaries/Operator/Edit.vue'
import {default as OperatorEditMaoObra} from '../views/Diaries/Operator/Edit.vue'

import {default as SupervisorCreate} from '../views/Diaries/Supervisor/Create.vue'
import {default as SupervisorEdit} from '../views/Diaries/Supervisor/Edit.vue'

import {default as ERPLaborView} from '../views/Diaries/ERP/LaborView.vue'
import {default as ERPEquipmentView} from '../views/Diaries/ERP/EquipmentView.vue'
import Users from '../views/Super/Users/Users.vue'
import User from '../views/Super/Users/User.vue'
import Works from '../views/Super/Users/Works.vue'
import CreateUser from '../views/Super/Users/CreateUser.vue'
import Roles from '../views/Super/Roles/Roles.vue'
import Role from '../views/Super/Roles/Role.vue'
import CreateRole from '../views/Super/Roles/CreateRole.vue'
import AddMaterial from '../views/Materials/AddMaterial.vue'
import Materials from '../views/Materials/Materials_version2.vue'
import Unities from '../views/Unities/Unities.vue'
import SubEmpreitadas from '../views/SubEmpreitadas/Subempreitadas.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import Logs from '../views/Logs/Logger.vue'

import ODCMachines from '../views/ODCMachines/ODCMachines.vue'
import CreateODCMachine from '../views/ODCMachines/CreateODCMachine.vue'

import ExternalCommunications from '../views/External/ExternalCommunications.vue'

import WorksSchedule from '../views/WorksSchedule/WorksSchedule.vue'
import CreateWorksSchedule from '../views/WorksSchedule/CreateWorksSchedule.vue'

import WorkerCards from '../views/WorkerCard/WorkerCards.vue'
import CreateWorkerCards from '../views/WorkerCard/CreateWorkerCards.vue'

import Templates from '../views/Templates/Templates.vue'
import CreateTemplates from '../views/Templates/CreateTemplatesV2.vue'
import EditTemplates from '../views/Templates/UpdateTemplates.vue'

import WorksConsult from '../views/Works/Consult.vue'

import Exports from '../views/Exports/Exports.vue'

import GuaranteesWorks from '../views/Guarantees/GuaranteesWorks.vue'
import CreateGuarantee from '../views/Guarantees/CreateGuarantee.vue'
import UpdateGuarantee from '../views/Guarantees/UpdateGuarantee.vue'

import CreateOperatorDiary from '@/views/Diaries/Operator/CreateDiary.vue'
import UpdateOperatorDiary from '@/views/Diaries/Operator/UpdateDiary.vue'

import CreateSupervisorDiary from "@/views/Diaries/Supervisor/CreateDiarySupervisor.vue"
import UpdateSupervisorDiary from "@/views/Diaries/Supervisor/UpdateSupervisorDiary.vue"

import Import_Garantias from "@/views/Imports/Import_Garantias.vue"

import ClientsODC from "@/views/ClientsODC/ClientsODC.vue"

import KPIS from '@/views/KPI/KPIS.vue'
import UpdateKPI from '@/views/KPI/UpdateKPI.vue'
import Seework from '@/views/Works/SeeWork.vue'

import Categorias from '@//views/Categorias/Categorias.vue'
import Atividades from '@//views/Atividades/Atividades.vue'

import Work_WorkUnits from '@/views/KPI/Work_WorkUnits.vue'

import Create_Work_WorkUnit from '@/views/KPI/Create_Work_WorkUnit.vue'
import Update_Work_WorkUnit from '@/views/KPI/Update_Work_WorkUnit.vue'

import CreateDiaryKPI from '@/views/Diaries/Kpi/CreateDiaryKPI.vue'
import UpdateDiaryKPI from '@/views/Diaries/Kpi/UpdateDiaryKPI.vue'
import DiariesKPI from '@/views/Diaries/Kpi/DiariesKPI.vue'

import DiariesAggregator from '@/views/Diaries/Aggregator/DiariesAggregator.vue'
import UpdateDiariesAggregator from '@/views/Diaries/Aggregator/UpdateDiariesAggregator.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/garantias/clients',
    name: 'Clientes',
    component: ClientsODC
  },
  {
    path: '/imports',
    name: 'Importações',
    component: Import_Garantias
  },
  {
    path: '/diarios/manobrador/registar',
    name: 'Registo Diário Manobrador',
    component: CreateOperatorDiary
  },
  {
    path: '/diarios/manobrador/:hash/:id_user/:type',
    name: 'Registo Diário Manobrador',
    component: UpdateOperatorDiary
  },
  {
    path: '/garantias/obras',
    name: "Consultar obras",
    component: GuaranteesWorks
  },
  {
    path: '/garantias/obras/create',
    name: "Criar obras",
    component: CreateGuarantee
  },
  {
    path: '/garantias/obras/:id',
    name: "Atualizar obras",
    component: UpdateGuarantee
  },
  {
    path: '/garantias/obras',
    name: "Consultar obras",
    component: GuaranteesWorks
  },
  {
    path: '/super/logs',
    name: 'Logs',
    component: Logs
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/configuracoes/criar/materiais',
    name: 'Registo Novo Material',
    component: AddMaterial
  },
  {
    path: '/configuracoes/materiais',
    name: 'Consultar registo material',
    component: Materials
  },
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    name: "login",
    path: '/login',
    component: Login
  },
  {
    name: "mfa",
    path: '/mfa',
    component: MFA
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Profile
  },
  {
    path: '/perfil/microsoft',
    name: 'Perfil_Microsoft',
    component: Profile
  },
  {
    path: '/diarios',
    name: 'Diários',
    component: Diaries
  },
  {
    path: '/diarios/v2',
    name: 'Diários',
    component: DiariesV2
  },
  {
    path: '/configuracoes/unities',
    name: 'Registo Unidades',
    component: Unities
  },
  {
    path: '/configuracoes/subempreitadas',
    name: 'Gerir subempreitadas',
    component: SubEmpreitadas
  },
  {
    path: '/kpi/registar',
    name: 'Registo Diário KPI',
    component: CreateDiaryKPI
  },
  {
    path: '/kpi/edit/:id',
    name: 'Edição Diário KPI',
    component: UpdateDiaryKPI
  },
  {
    path: '/kpi/lista-de-diarios',
    name: 'Edição Diário KPI',
    component: DiariesKPI
  },
  {
    path: '/diarios/dia',
    name: 'Diárias por dia',
    component: DiariesAggregator
  },
  {
    path: '/diarios/dia/:day/:work_id',
    name: 'Edição Diário Dia',
    component: UpdateDiariesAggregator
  },
  {
    path: '/diarios/encarregado/registar',
    name: 'Registo Diário Encarregado',
    component: CreateSupervisorDiary
  },
  {
    path: '/diarios/encarregado/editar/:hash/:id_user',
    name: 'Registo Diário Encarregado',
    component: UpdateSupervisorDiary
  },
  {
    path: '/diarios/view/erp/equipment/:id',
    name: 'Registo Diário Equipamentos - ERP',
    component: ERPEquipmentView
  },
  {
    path: '/diarios/view/erp/labor/:id',
    name: 'Registo Diário Mão Obra - ERP',
    component: ERPLaborView
  },
  {
    path: '/super/utilizadores',
    name: 'Consultar Utilizadores',
    component: Users
  },
  {
    path: '/super/utilizadores/registar',
    name: 'Registo Utilizador',
    component: CreateUser
  },
  {
    path: '/super/utilizadores/:id',
    name: 'Consultar Utilizador',
    component: User
  },
  {
    path: '/super/utilizadores/:id/obras',
    name: 'Gerir Obras',
    component: Works
  },
  {
    path: '/super/roles',
    name: 'Consultar Roles',
    component: Roles
  },
  {
    path: '/super/roles/registar',
    name: 'Registo Role',
    component: CreateRole
  },
  {
    path: '/super/roles/:id',
    name: 'Consultar Role',
    component: Role
  },
  {
    path: '/frota/odc_machines',
    name: 'Consultar máquinas',
    component: ODCMachines
  },
  {
    path: '/frota/works_schedule',
    name: 'Consultar obras',
    component: WorksSchedule
  },
  {
    path: '/frota/works_schedule/registar',
    name: 'Registar horário de obras',
    component: CreateWorksSchedule
  },
  {
    path: '/frota/works_schedule/editar/:id',
    name: 'Atualizar horário de obra',
    component: CreateWorksSchedule
  },
  {
    path: '/frota/worker_card',
    name: 'Consultar cartões de utilizador',
    component: WorkerCards
  },
  {
    path: '/frota/worker_card/registar',
    name: 'Registar cartões de utilizador',
    component: CreateWorkerCards
  },
  {
    path: '/frota/worker_card/editar/:id',
    name: 'Atualizar cartões de utilizador',
    component: CreateWorkerCards
  },
  {
    path: '/frota/odc_machines/registar',
    name: 'Registar máquina',
    component: CreateODCMachine
  },
  {
    path: '/frota/odc_machines/editar/:id',
    name: 'Atualizar máquina',
    component: CreateODCMachine
  },
  {
    path: '/frota/external_communications',
    name: 'Comunicações externas',
    component: ExternalCommunications
  },
  {
    path: '/configuracoes/templates/registar',
    name: 'Registar template',
    component: CreateTemplates
  },
  {
    path: '/configuracoes/templates/editar/:id',
    name: 'Atualizar template',
    component: EditTemplates
  },
  {
    path: '/configuracoes/templates',
    name: 'Templates',
    component: Templates
  },
  {
    path: '/works_consult',
    name: 'Consulta de Obras',
    component: WorksConsult
  },
  {
    path: '/exports',
    name: 'Exportações',
    component: Exports
  },
  {
    name: "reset",
    path: '/reset/:id/:hash',
    component: Reset
  },
  {
    name: "verify_microsoft",
    path: '/verify-email-microsoft',
    component: VerifyMicrosoft
  },
  {
    name: "verify",
    path: '/verify-email/:id/:hash',
    component: Verify
  },
  {
    name:"KPIS_articulado",
    path: "/kpi/list",
    component: KPIS
  },
  {
    name:"KPIS_articulado",
    path: "/kpi/list/articulado/:id",
    component: UpdateKPI
  },
  {
    name:"Categorias",
    path: "/kpi/categories",
    component: Categorias
  },
  {
    name:"Atividades",
    path: "/kpi/activities",
    component: Atividades
  },
  {    
    path: "/kpi/work_work_units",
    component: Work_WorkUnits
  },
  {    
    path: "/kpi/work_work_units/create",
    component: Create_Work_WorkUnit
  },
  {    
    path: "/kpi/work_work_units/:id",
    component: Update_Work_WorkUnit
  },
  {
    name:"SeeWork",
    path: "/works_consult/kpi-config/:id",
    component: Seework
  },
  
  { name: "page500", path: '/500', component: Page500 },
  { name: "page404", path: '*', component: Page404 }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
