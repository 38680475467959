import API from "./API";

export default class Works extends API
{
    static async search(filter) {
        return this.get("api/admin/works",{params: filter});
    }
    
    static async getUsers(work_id){
        return this.get('api/admin/works/get-users/' + work_id)
    }

    static async getMachines(work_id){
        return this.get('api/admin/works/get-machines/' + work_id)
    }

    static async getMachinesByUser(work_id){
        return this.get('api/admin/works/get-machines-by-user/' + work_id)
    }

    static async addMachine(work_id, params){
        return this.post("api/admin/works/add-machine/" + work_id, params);
    }
    
    static async deleteMachine(work_id, params){
        return this.delete("api/admin/works/delete-machine/" + work_id, {params: params});
    }

    static async addUser(work_id, params){
        return this.post("api/admin/works/add-user/" + work_id, params);
    }

    static async deleteUser(work_id, params){
        return this.delete("api/admin/works/delete-user/" + work_id, {params: params});
    }

    static async update(fields, id) {
        return this.put("api/admin/works/" + id, fields);
    }

    static async changeApprovalLevel(fields, id){
        return this.put("api/admin/works/change-level/" + id, fields);
    }
}