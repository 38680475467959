<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <validation-observer ref="form" v-slot="{ invalid }" v-if="!loading">
            <v-form @submit.prevent="submit">
                <v-row justify="start">
                    <v-col cols="12" md="4">
                        <validation-provider v-if="method=='create'" v-slot="{ errors }" vid="Obras" name="Obras" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                :items="obras"
                                dense outlined
                                item-value="Id"
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                :error-messages="errors"
                                v-model="fields.work_id"
                                label="Obra *"
                                @change="importMQT"
                            />
                        </validation-provider>
                        <v-text-field
                            dense outlined
                            label="Obra *"
                            :disabled="true"
                            :value="fields.work_code + ' - ' + fields.work_name"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="fields.work_id != null && loadingContent == false">
                    <v-col cols="12">
                        <v-simple-table class="d-none d-lg-block" fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Natureza
                                        </th>
                                        <th class="text-center">
                                            Sub-Atividade
                                        </th>
                                        <th class="text-center">
                                            Cód. Internos
                                        </th>
                                        <th class="text-center">
                                            U.O.
                                        </th>
                                        <th class="text-center">
                                            Unidade
                                        </th>
                                        <th class="text-center">
                                            Rend. Esperado /h
                                        </th>
                                        <th class="text-center">
                                            Total produção
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in getLines" :key="index"
                                    >
                                        <td class="text-left">
                                            <validation-provider v-slot="{ errors }" vid="Natureza" name="Natureza" rules="required">
                                                <v-autocomplete
                                                    :items="getAtividades"
                                                    class="mt-4"
                                                    item-value="codigo_atividade"
                                                    :item-text="item => item.codigo_atividade + ' - ' + item.atividade"
                                                    label="Natureza *"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    v-model="item.codigo_atividade"
                                                    @change="getSubAtividades(item)"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td class="text-center">
                                            <validation-provider v-slot="{ errors }" vid="Sub-atividade" name="Sub-atividade" rules="required">
                                                <v-autocomplete
                                                    :items="item.sub_atividades"
                                                    class="mt-4"
                                                    item-value="id"
                                                    :item-text="item => item.codigo_sub_atividade + ' - ' + item.sub_atividade"
                                                    label="Sub-atividade *"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    v-model="item.codigo_sub_atividade"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td class="text-center">
                                            <v-row>
                                                <v-col cols="6">
                                                    <div v-html="getCodigosInternos(item)"></div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-btn @click="openArticulados.item = item; openArticulados.show = true;" class="primary" fab x-small>
                                                        <v-icon small>mdi-swap-horizontal</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td class="text-center">
                                            <validation-provider v-slot="{ errors }" vid="Unidade Obra" name="Unidade Obra" rules="required">
                                                <v-text-field
                                                    class="mt-4"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    label="Unidade Obra *"
                                                    v-model="item.unidade_obra"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td class="text-center">
                                            <validation-provider v-slot="{ errors }" vid="Unidade" name="Unidade" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    :items="unidades_medida"
                                                    item-value="id"
                                                    item-text="abreviacao"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    label="Unidade *"
                                                    v-model="item.unidade_medida_id"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td class="text-center">
                                            <validation-provider v-slot="{ errors }" vid="Rendimento esperado" name="Rendimento esperado" rules="required">
                                                <v-text-field
                                                    class="mt-4"
                                                    type="number"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    label="Rendimento esperado /h *"
                                                    v-model="item.rendimento_esperado"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td class="text-center">
                                            <validation-provider v-slot="{ errors }" vid="Total produção" name="Total produção" rules="required">
                                                <v-text-field
                                                    class="mt-4"
                                                    type="number"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    label="Total produção *"
                                                    disabled
                                                    v-model="item.total"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                small
                                                color="error"
                                                @click="deleteLinha(item.id)"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row v-if="fields.work_id != null && loadingContent == false">
                    <v-btn style="margin: auto; display: block;" fab @click="addNewLine" class="primary">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-row>
                <v-divider class="mt-6"></v-divider>
                <v-row class="mt-6">
                    <v-btn rounded class="success mt-4" :disabled="invalid" type="submit" style="margin: auto; display: block;">
                        Gravar
                    </v-btn>
                </v-row>
            </v-form>
        </validation-observer>

        <v-dialog v-model="openArticulados.show">
            <v-card class="elevation-0 ma-0 pa-0">
                <v-card-title class="primary white--text">Articulado</v-card-title>
                <v-card-text>
                    <v-simple-table class="d-none d-lg-block" fixed-header>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Código
                                    </th>
                                    <th class="text-center">
                                        Titulo
                                    </th>
                                    <th class="text-center">
                                        Descrição
                                    </th>
                                    <th class="text-center">
                                        Op.
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in articulado_in_use" :key="item.id">
                                    <td :class="item.unidade == null ? 'font-weight-bold' : ''">
                                        {{item.codigo}}
                                    </td>
                                    <td :class="item.unidade == null ? 'font-weight-bold' : ''">
                                        {{item.title}}
                                    </td>
                                    <td>
                                        {{item.descricao}}
                                    </td>
                                    <td>
                                        <v-switch
                                            v-if="item.unidade != null"
                                            v-model="item.selected"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>   
                </v-card-text>
                <v-card-actions>
                    <v-btn class="primary" @click="saveListaArticulado" style="margin: auto; display: block;">
                        Gravar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmModal 
            @yes="redirectToMQT" 
            @close-modal="fields.work_id = null; redirectMQT.show = false" 
            v-show="redirectMQT.show" 
            :title="'Duplicação!'" 
            :message="'Já existe um plano para essa obra. Deseja entrar no mesmo e editar?'"/>

      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Tarefa gravada com sucesso!
      </DialogSuccess>

      <ErrorSnackbar v-model="errorMQT" :message="'Não existe mapa de articulados para esta obra'"/>

    </v-container>
</template>
<script>
import Obra from '@/api/Obra.js'
import KPIS from '@/api/KPIS.js'
import UnidadesObra from '@/api/UnidadesObra.js'
import Atividades from '@/api/Atividades.js'
import Unidades from '@/api/Unidades.js'
import dayjs from 'dayjs'
import DialogSuccess from '@/components/UI/DialogSuccess.vue';
import ConfirmModal from '@/components/Modals/ConfirmModal.vue';
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";

export default{
    name:"Work_WorkUnit",
    components:{
        DialogSuccess,
        ConfirmModal,
        ErrorSnackbar
    },
    data(){
        return{
            obras: [],
            loadingContent: true,
            errorMQT: false,
            success: false,
            loading: true,
            atividades: [],
            unidades_medida: [],
            mapa_quantidade_trabalhos: null,
            fields:{
                work_id: null,
                lines: []
            },
            openArticulados:{
                show: false,
                item: null
            }, 
            articulado_in_use: [],
            error:{
                title: null,
                message: null
            },
            redirectMQT:{
                show: false,
                id: null
            }
        }
    },
    props: {
        unit: Object,
        method: String,
    },
    watch:{
        'openArticulados.show':{
            immediate: true,
            handler(val){
                if(val)
                    this.getMapaArticulado();
                else
                    this.articulado_in_use = []
            }
        },
        unit: {
            immediate: true, 
            handler (val) {
                if(!val) {
                    return;
                }

                let data = {...val};

                this.fields = data;

                this.importMQT()
            }
        },
    },
    mounted(){
        this.fillBaseData();
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        deleteLinha(item){
            let i = 0
            let pos = -1
            
            this.fields.lines.forEach(element => {
                if(element.id == item)
                    pos = i
                
                i++
            });

            if(pos != -1)
                this.fields.lines.splice(pos, 1)
        },
        saveListaArticulado(){
            // Clean arrays
            let total_producao = 0;
            this.articulado_in_use.forEach(element => {
                if(element.selected){
                    
                    total_producao += parseInt(element.qty)
                    let i = 0;
                    this.fields.lines.forEach(line_item => {
                        if(line_item.id == this.openArticulados.item.id){
                            this.fields.lines[i].codigos_internos = []
                        }
                        i++
                    });
                }
            });
            // Add Arrays
            this.articulado_in_use.forEach(element => {
                if(element.selected){
                    
                    let i = 0;
                    this.fields.lines.forEach(line_item => {
                        if(line_item.id == this.openArticulados.item.id){
                            this.fields.lines[i].codigos_internos.push(element.id)
                            this.fields.lines[i].total = total_producao
                        }
                        i++
                    });
                }
            });

            this.openArticulados.show = false
        },
        async importMQT(){
            this.loadingContent = true
            if(this.method == 'create'){
                await UnidadesObra.findByWork(this.fields.work_id).then((resp)    =>  {
                    if(resp.data.result != null){
                        this.redirectMQT.id = resp.data.result
                        this.redirectMQT.show = true
                    }else{
                        this.redirectMQT.id = null
                        this.redirectMQT.show = false
                    }
                })
            }
            await KPIS.getByWork(this.fields.work_id).then((resp)  =>  {
                if(resp.data == '' || resp.data == null){
                    this.errorMQT = true
                    this.fields.work_id = null
                    return
                }
                this.mapa_quantidade_trabalhos = resp.data
            })

            this.loadingContent = false
        },
        redirectToMQT(){
            this.$router.push('/kpi/work_work_units/' + this.redirectMQT.id)
        },
        getCodigosInternos(item){
            let final_string = ''
            item.codigos_internos.forEach(element => {
                this.mapa_quantidade_trabalhos.articulado.forEach(articulado => {
                    if(articulado.id == element)
                    final_string = final_string + articulado.codigo + '<br/>';
                });
                
            });
            return final_string
        },
        async getSubAtividades(item){
            if(item == null)
                return []

            await Atividades.getSubAtividade(item.codigo_atividade).then((resp)  =>  {
                item.sub_atividades = resp.data
            })
        },
        addNewLine(){
            this.fields.lines.push({
                id: dayjs().unix() + '-new',
                codigo_atividade: null,
                sub_atividades: [],
                codigo_sub_atividade: null,
                codigos_internos: [],
                unidade_obra: null,
                unidade_medida_id: null,
                rendimento_esperado: null,
                total: null
            })
        },
        async fillBaseData(){
            let data = {
                tipo: 'O'
            }
            
            if(this.method == 'create'){
                await Obra.all(data).then((resp)   =>  {
                    this.obras = resp.data;
                });
            }

            Atividades.all({}).then((resp)  =>  {
                this.atividades = resp.data
            })

            Unidades.getList().then((resp)  =>  {
                this.unidades_medida = resp.data
            })

            this.loading = false
        },
        getMapaArticulado(){       
            this.articulado_in_use = []     
            this.mapa_quantidade_trabalhos.articulado.forEach(element => {
                let existe = false
                this.openArticulados.item.codigos_internos.forEach(c_internos => {
                    if(c_internos == element.id)
                        existe = true
                });
                
                this.articulado_in_use.push({
                    id: element.id,
                    title: element.title,
                    codigo: element.codigo,
                    unidade: element.unidade,
                    descricao: element.descricao,
                    selected: existe,
                    qty: element.qty
                })
            });

            return this.articulado_in_use
        },
        submit(){
            this.loading = true;

            UnidadesObra[this.method](this.fields, this.fields.id).then(() => {

                this.success = true;

                this.loading = false;

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    },
    computed:{
        getLines(){
            return this.fields.lines
        },
        getAtividades(){
            return this.atividades
        }
    }
}
/*
        'work_id'                               =>  ["required", Rule::exists("works", "id")],
        'lines.*.codigo_atividade'              =>  ["required", Rule::exists("ODC_Atividade", "id")],
        'lines.*.codigo_sub_atividade'          =>  ["required", Rule::exists("ODC_Sub_Atividade", "id")],
        'lines.*.unidade_obra'                  =>  ["required"],
        'lines.*.unidade_medida_id'             =>  ["required", Rule::exists("ListaUnidades", "id")],
        'lines.*.rendimento_esperado'           =>  ["required"],
        'lines.*.codigos_internos'              =>  ["required", "array"]
    ];
}
*/
</script>