<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="d-none d-lg-block warning white--text">
                        Materiais
                    </v-card-title>
                    <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                        Materiais
                    </v-card-title>
                    <v-card-text :key="reloader">
                        <v-simple-table class="d-none d-lg-block" fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" width="15%">
                                            Frente Obra
                                        </th>
                                        <th class="text-left" width="15%">
                                            Classe
                                        </th>
                                        <th class="text-left" width="15%">
                                            Artigo
                                        </th>
                                        <th class="text-left" width="7%">
                                            Un
                                        </th>
                                        <th class="text-left" width="7%">
                                            QTD
                                        </th>
                                        <th class="text-left" width="5%">
                                            Nota
                                        </th>
                                        <th class="text-left" width="10%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in computeMateriais" :key="item.id"
                                    >
                                        <td>
                                            <div v-if="showFrente(item)">
                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="|">
                                                    <v-autocomplete                                                                                
                                                        v-model="item.frente_id"
                                                        :items="frentes_obra"
                                                        outlined dense
                                                        class="mt-4"
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Frentes de Obra *"
                                                        @change="changeFrenteObraV2(item)"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="showClasse(item)">
                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                    <v-autocomplete
                                                        v-model="item.classe_id"
                                                        :items="item.available_classes"
                                                        class="mt-4"
                                                        outlined dense
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Classe *"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="equipamento" name="equipamento" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="materiais"
                                                    :item-text="item => item.artigo + ' - ' + item.descricao"
                                                    item-value="id"
                                                    label="Material"
                                                    :error-messages="errors"
                                                    v-model="item.material"
                                                    @change="changeItem(item)"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="materiais"
                                                    :item-text="item => item.unidade_base"
                                                    item-value="id"
                                                    label="Material"
                                                    v-model="item.material"
                                                    disabled
                                                />
                                        </td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="QTD"
                                                outlined dense
                                                placeholder="Insira qtd."
                                                v-model="item.qty"
                                            />
                                        <td>
                                            <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab x-small @click="$emit('nota', item, 'material')">
                                                <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                <v-badge
                                                    v-else
                                                    color="orange"
                                                    dot
                                                    overlap
                                                >
                                                    <v-icon>mdi-note-plus</v-icon>
                                                </v-badge>
                                            </v-btn>
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                small
                                                color="error"
                                                @click="deleteMaterial(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>                            
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-data-iterator
                            :items="computeMateriais"
                            item-key="id"
                            :items-per-page="-1"
                            hide-default-footer
                            class="d-block d-lg-none"
                        >
                        <template v-slot:default="{ items }">
                            <v-row>
                                <v-col
                                    v-for="item in computeMateriais"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                                        <v-list
                                            dense
                                        >
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <div v-if="showFrente(item)">
                                                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="|">
                                                            <v-autocomplete                                                                                
                                                                v-model="item.frente_id"
                                                                :items="frentes_obra"
                                                                outlined dense
                                                                class="mt-4"
                                                                :item-text="ob => ob.Descricao"
                                                                item-value="ID"
                                                                :error-messages="errors"
                                                                label="Frentes de Obra *"
                                                                @change="changeFrenteObraV2(item)"
                                                            />
                                                        </validation-provider>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <div v-if="showClasse(item)">
                                                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                            <v-autocomplete
                                                                v-model="item.classe_id"
                                                                :items="item.available_classes"
                                                                class="mt-4"
                                                                outlined dense
                                                                :item-text="ob => ob.Descricao"
                                                                item-value="ID"
                                                                :error-messages="errors"
                                                                label="Classe *"
                                                            />
                                                        </validation-provider>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>


                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <validation-provider v-slot="{ errors }" vid="equipamento" name="equipamento" rules="required">
                                                        <v-autocomplete
                                                            class="mt-4"
                                                            outlined dense
                                                            :items="materiais"
                                                            :item-text="item => item.artigo + ' - ' + item.descricao"
                                                            item-value="id"
                                                            label="Material"
                                                            :error-messages="errors"
                                                            v-model="item.material"
                                                            @change="changeItem(item)"
                                                        />
                                                    </validation-provider>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-autocomplete
                                                        class="mt-4"
                                                        outlined dense
                                                        :items="materiais"
                                                        :item-text="item => item.unidade_base"
                                                        item-value="id"
                                                        label="Material"
                                                        v-model="item.material"
                                                        disabled
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="QTD"
                                                        outlined dense
                                                        placeholder="Insira qtd."
                                                        v-model="item.qty"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="$emit('nota', item, 'material')">
                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                        <v-badge
                                                            v-else
                                                            color="orange"
                                                            dot
                                                            overlap
                                                        >
                                                            <v-icon>mdi-note-plus</v-icon>
                                                        </v-badge>
                                                    </v-btn>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn
                                                        dark
                                                        small
                                                        color="error"
                                                        @click="deleteMaterial(item.id)"
                                                        >
                                                        <v-icon small>mdi-delete</v-icon>
                                                    </v-btn>        
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                        </v-data-iterator>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn fab @click="addMaterial" class="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Materiais from "@/api/Materiais.js"
import Classe from "@/api/Classe.js"
import dayjs from 'dayjs'

export default{
    name:"Materials",
    props:{
        fields: Object,
        frentes_obra: Array,
        hideFrentes: Boolean
    },
    watch:{
        'fields.materiais':{
            immediate: true,
            handler(val){
                console.log(val)
            }
        }
    },
    data(){
        return{
            classes: [],
            materiais: [],
            loading: true,
            reloader: 0
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        showClasse(item){
            if(item.classeNeeded)
                return true

            return false
        },
        showFrente(item){
            if(this.fields.obra_id != null && this.hideFrentes == false)
                return true

            return false
        },
        async changeFrenteObraV2(item){

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == item.frente_id)
                    frente = element
            });


            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }


                await Classe.getClasses(data).then((resp) =>  {
                    item.available_classes = resp.data

                    if(item.available_classes.length == 0){
                        item.classeNeeded = false
                    }else item.classeNeeded = true
                    
                })

                
            }
        },
        changeItem(item){
            this.materiais.forEach(element => {
                if(element.id == item.material){
                    item.unidade = element.unidade_base
                }
            });

            console.log(item)
        },
        deleteMaterial(id){
            let pos = -1    
            let i = 0
            this.fields.materiais.forEach(element => {
                if(element.id == id)
                    pos = i

                i++
            });

            this.fields.deleteMaterial.push(id)
            this.fields.materiais.splice(pos, 1)
            this.reloader++
        },        
        classesPorObra(){
            // Também temos de verificar se esta obra tem classes
            Classe.getClassesByObraPai(this.fields.obra_id).then((resp) =>  {
                this.classes = resp.data
               
            })
        },
        addMaterial(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            this.fields.materiais.push({
                id: dayjs().unix() + '-new',
                material: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                un: "-",
                qty: 0,
                local: null,
                familia: null,
                subfamilia: null,
                Unidade: '-',
                options: ""
            });
            
            this.reloader++
        },
        async fillBaseData(){
            let materialsData ={
                id_frente_obra: "null",
                id_obra_classe: "null",
                obra_id: this.fields.obra_id
            }

            await Materiais.getList(materialsData).then((resp)    =>  {
                this.materiais = resp.data
                
            })

            if(this.hideFrentes){
                this.classesPorObra()
            }

            this.loading = false

        },
        getUnity(i){
            if(i==null)
                return "-"

            if(i.product == null)
                return '-'
                
            return i.product.unidade || "-"
        },
    },
    computed:{
        computeMateriais(){
            let returner = []            

            this.fields.materiais.forEach(element => {
                if(element.classe_id != null){
                    if(this.hideFrentes == false){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.classes
                    }
                    element.classeNeeded = true
                }
                
                returner.push(element)
            });

            return returner
        }     
    }
}
</script>