import API from "./API";

export default class Templates extends API
{
    static async create(fields) {
        return this.post("api/admin/templates", fields);
    }

    static async edit(fields, id) {
        return this.put("api/admin/templates/" + id, fields);
    }

    static async getByCodes(fields){
        return this.get("api/admin/templates/getByCodes", {params: fields});
    }

    static async getById(id){
        return this.get("api/admin/templates/getById/" + id);
    }

    static async deleteTemplate(id) {
        return this.delete("api/admin/templates/" + id);
    }

    static async search(filter) {
        return this.get("api/admin/templates",{params: filter});
    }
}