<template>
    <section class="mt-16">
      <v-row class="d-none d-lg-block mx-3 px-3">
        <v-col cols="12">
          <v-breadcrumbs
              large
              :items="crumbs"
              divider="/"
              light
              :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
            ></v-breadcrumbs>
      
            <h1 class="primary--text">
              <v-icon color="primary">mdi-bulldozer</v-icon> <span>Atualizar template</span>
            </h1>
      
            <div class="mt-16">
              <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
      
              <template v-if="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row class="mt-6" align="center" justify="space-around">
                  <v-skeleton-loader
                      type="button"
                    ></v-skeleton-loader>
                </v-row>
              </template>
              <Template v-else method="update" :template="template"></Template>
            </div>
          </v-col>
        </v-row>

        <v-row class="d-block d-lg-none">
        <v-col cols="12" offset="0" md="10" offset-md="1">
          <v-breadcrumbs
              large
              :items="crumbs"
              divider="/"
              light
              :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
            ></v-breadcrumbs>
      
            <h1 class="primary--text">
              <v-icon color="primary">mdi-bulldozer</v-icon> <span>Atualizar template</span>
            </h1>
      
            <div class="mt-16">
              <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
      
              <template v-if="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row class="mt-6" align="center" justify="space-around">
                  <v-skeleton-loader
                      type="button"
                    ></v-skeleton-loader>
                </v-row>
              </template>
              <Template v-else method="update" :template="template"></Template>
            </div>
          </v-col>
        </v-row>
    </section>
  </template>
  <script>
  import Template from '@/components/Templates/Template'
  import TemplateAPI from "@/api/Templates.js";
  
  export default {
    components: {
        Template
    },
    data: () => ({
        template: {},
        loading:true
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "templates.update"])) {
        this.$router.push('/admin');
      }
  
      TemplateAPI.getById(this.$route.params.id).then(({data}) => { this.template = data; this.loading = false;});
    },
    methods:{
      
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/',
            exact: true,
          },
          {
            text: 'Templates',
            disabled: false,
            to: '/configuracoes/templates',
            exact: true,
          },
          {
            text: 'Atualizar template',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  