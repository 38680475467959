<template>
    <section class="mt-16">
      <v-row class="d-none d-lg-block mx-3 px-3">
            <v-col cols="12">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    ></v-breadcrumbs>
  
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo de Template</span>
                    </h1>
  
                    <div class="mt-16">
                      <Template method="create"></Template>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-block d-lg-none">
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    ></v-breadcrumbs>
  
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo de Template</span>
                    </h1>
  
                    <div class="mt-16">
                      <Template method="create"></Template>
                </div>
            </v-col>
        </v-row>
    </section>
  </template>
  <script>
  import Template from '@/components/Templates/Template'
  
  export default {
    components: {
        Template
    },
    data: () => ({
      
    }),
    mounted(){
  
      /*if(!this.$root.session.hasPermission(["super", "clients.create"])) {
        this.$router.push('/admin');
      }*/
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Config. de Obra',
            disabled: true,
            exact: true,
          },
          {
            text: 'Registo Template',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  