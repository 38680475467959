<template>
    <v-container fluid>
        <MiniAlerts style="white-space: pre-line" v-if="alert.showAlert" :message="alert.message" />
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="createPD">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-menu
                            v-if="method == 'create'"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                class="d-none d-lg-block"
                                v-model="fields.Data"
                                label="Insira Data"
                                outlined dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            <v-text-field 
                                class="d-block d-lg-none" 
                                v-model="fields.Data" 
                                type="date" 
                                id="birthday" 
                                label="Insira data"
                                dense outlined
                                :max="new Date(new Date()+1).toISOString().substr(0, 10)"
                            />
                            
                            </v-date-picker>
                            </template>
                            <v-date-picker
                                :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.Data"
                                @input="menu2 = false"
                                @change="dataChanged"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                            v-else
                            dense outlined
                            v-model="fields.Data"
                            disabled
                            label="Data"
                        />
                    </v-col>
                    <v-col cols="12" md="4" v-if="fields.Data != null">
                        <validation-provider v-slot="{ errors }" vid="Obras" name="Obras" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.obra_id"
                                :items="obras"
                                outlined dense
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                item-value="Id"
                                :error-messages="errors"
                                label="Obras *"
                                @change="changeWork"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3" v-if="fields.obra_id != null && frentes_obra.length > 0 && hideFrentes == false">
                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.frente_id"
                                :items="frentes_obra"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Frentes de Obra *"
                                @change="changeFrenteObra"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3" v-if="classeNeeded">
                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.classe_id"
                                :items="classes"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Classe *"
                                @change="changeClasse"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>                
                <v-row v-if="showContent">
                    <v-col cols="12">
                        <v-simple-table class="d-none d-lg-block">
                            <template v-slot:default>
                                <thead class="primary">
                                    <tr>
                                        <th width="20%" class="text-center white--text text-caption font-weight-bold">
                                            Equipamento
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HO
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HS
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HA
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            KM
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Hi
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            H
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Local
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Utilizador
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HN
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HExt
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Diesel
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Óleo
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Adblue
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in getLines" v-bind:key="item.id">
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Equipamento" name="Equipamento" rules="required">
                                                <div v-if="checkNew(item)">
                                                    <v-autocomplete
                                                        v-if="item.accessory == false"
                                                        :disabled="disableBox(item)"
                                                        v-model="item.equip"
                                                        :items="getEquipamentos"
                                                        hide-selected             
                                                        :error-messages="errors"
                                                        item-value="Id"
                                                        :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                        label="Equipamento"
                                                    ></v-autocomplete>
                                                    <v-autocomplete
                                                        v-else
                                                        v-model="item.equip"
                                                        :disabled="disableBox(item)"
                                                        :items="unassignedMachines"
                                                        item-value="Id"
                                                        hide-selected          
                                                        :error-messages="errors"
                                                        :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                        label="Acessório"
                                                    />
                                                </div>
                                                <div v-else class="text-center">
                                                    <span class="text-caption font-weight-bold">{{getVehicleName(item)}}</span>
                                                </div>
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="HO" name="HO" rules="required">
                                                <v-text-field
                                                    label="HO"
                                                    placeholder="Insira HO"
                                                    v-model="item.ho"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="HS" name="HS" rules="required">
                                                <v-text-field
                                                    label="HS"
                                                    placeholder="Insira HS"
                                                    v-model="item.hs"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="HA" name="HA" rules="required">
                                                <v-text-field
                                                    label="HA"
                                                    placeholder="Insira HA"
                                                    v-model="item.ha"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="KM" name="KM" rules="required">
                                                <v-text-field
                                                    label="KM"
                                                    placeholder="Insira KM"
                                                    v-model="item.km"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Hi" name="Hi" rules="required">
                                                <v-text-field
                                                    label="Hi"
                                                    placeholder="Insira H inicial"
                                                    v-model="item.hi"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="H" name="H" rules="required">
                                                <v-text-field
                                                    label="H"
                                                    placeholder="Insira H"
                                                    v-model="item.h"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Local" name="Local" rules="|">
                                                <v-text-field
                                                    label="Local"
                                                    placeholder="Insira local"
                                                    v-model="item.local"
                                                    @click="openEditorModal(item)"   
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <Tooltip
                                                v-if="item.CodigoMO != null"
                                                color="warning"
                                                :text="item.name"
                                                top
                                            >
                                                <span class="text-caption text-center">{{ item.CodigoMO == null ? '-' : item.CodigoMO}}</span>
                                            </Tooltip>
                                            <span class="text-caption text-center" v-else>-</span>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="HN" name="HN" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="HN"
                                                    placeholder="Insira HN"
                                                    v-model="item.hn"
                                                    :disabled="item.accessory"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="HExt" name="HExt" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="HExt"
                                                    placeholder="Insira HExt"
                                                    v-model="item.hext"
                                                    :disabled="item.accessory"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Diesel" name="Diesel" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="Qtd. Diesel"
                                                    placeholder="Insira Qty"
                                                    v-model="item.diesel"
                                                    :disabled="item.accessory"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Óleo" name="Óleo" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="Qtd. Óleo"
                                                    placeholder="Insira Qty"
                                                    v-model="item.oil"
                                                    :disabled="item.accessory"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Adblue" name="Adblue" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="Qtd. Adblue"
                                                    placeholder="Insira Qty"
                                                    v-model="item.adblue"
                                                    :disabled="item.accessory"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                x-small
                                                fab
                                                color="error"
                                                @click="deleteLine(item.id)"
                                                >
                                                <v-icon x-small>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-data-iterator
                            :items="line"
                            item-key="id"
                            :items-per-page="-1"
                            hide-default-footer
                            class="d-block d-lg-none"
                        >

                        <template v-slot:default="props">
                            <v-card v-for="item in props.items" :key="item.id" outlined class="mt-4 elevation-0">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Equipamento" name="Equipamento" rules="required">
                                                <div v-if="checkNew(item)">
                                                    <v-combobox
                                                        v-if="item.accessory == false"
                                                        :disabled="disableBox(item)"
                                                        v-model="item.equip"
                                                        :items="getEquipamentos"
                                                        hide-selected             
                                                        :error-messages="errors"
                                                        item-value="Id"
                                                        :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                        label="Equipamento"
                                                    ></v-combobox>
                                                    <v-combobox
                                                        v-else
                                                        v-model="item.equip"
                                                        :disabled="disableBox(item)"
                                                        :items="unassignedMachines"
                                                        item-value="Id"
                                                        hide-selected          
                                                        :error-messages="errors"
                                                        :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                        label="Acessório"
                                                    />
                                                </div>
                                                <div v-else class="text-center">
                                                    <span class="text-caption font-weight-bold">{{getVehicleName(item)}}</span>
                                                </div>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="HO" name="HO" rules="required">
                                                <v-text-field
                                                    label="HO"
                                                    placeholder="Insira HO"
                                                    v-model="item.ho"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="HS" name="HS" rules="required">
                                                <v-text-field
                                                    label="HS"
                                                    placeholder="Insira HS"
                                                    v-model="item.hs"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="HA" name="HA" rules="required">
                                                <v-text-field
                                                    label="HA"
                                                    placeholder="Insira HA"
                                                    v-model="item.ha"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="km" name="km" rules="required">
                                                <v-text-field
                                                    label="KM"
                                                    placeholder="Insira KM"
                                                    v-model="item.km"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Hi" name="Hi" rules="required">
                                                <v-text-field
                                                    label="Hi"
                                                    placeholder="Insira H inicial"
                                                    v-model="item.hi"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="H" name="H" rules="required">
                                                <v-text-field
                                                    label="H"
                                                    placeholder="Insira H"
                                                    v-model="item.h"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Local" name="Local" rules="|">
                                                <v-text-field
                                                    label="Local"
                                                    placeholder="Insira local"
                                                    v-model="item.local"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Utilizador" name="Utilizador" rules="|">
                                                <v-text-field
                                                    disabled
                                                    label="Utilizador"
                                                    placeholder="Utilizador"
                                                    :value="item.CodigoMO == null ? '-' : item.CodigoMO + ' - ' + item.name"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="HN" name="HN" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="HN"
                                                    placeholder="Insira HN"
                                                    v-model="item.hn"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="HExt" name="HExt" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="HExt"
                                                    placeholder="Insira HExt"
                                                    v-model="item.hext"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Diesel" name="Diesel" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="Diesel"
                                                    placeholder="Insira Diesel"
                                                    v-model="item.diesel"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Óleo" name="Óleo" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="Óleo"
                                                    placeholder="Insira óleo"
                                                    v-model="item.oil"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Adblue" name="Adblue" :rules="item.accessory ? '' : 'required'">
                                                <v-text-field
                                                    label="Adblue"
                                                    placeholder="Insira Adblue"
                                                    v-model="item.adblue"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn
                                                dark
                                                x-small
                                                fab
                                                color="error"
                                                @click="deleteLine(item.id)"
                                                >
                                                <v-icon x-small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </template>
                            
                        </v-data-iterator>

                    </v-col>
                </v-row>
                <v-row justify="center" v-if="showContent">
                    <v-col cols="12" md="auto">
                        <v-btn
                            fab
                            dark
                            small
                            color="primary"
                            class="mt-4 mb-4"
                            @click="addLineToTable"
                        >
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-btn
                            fab
                            dark
                            small
                            class="mt-4 mb-4"
                            color="warning"
                            @click="addAccessoryLine"
                            >
                            <v-icon small>mdi-tag</v-icon>
                        </v-btn>
                    </v-col>                    
                </v-row>
                <v-divider></v-divider>
                <v-row v-if="showContent" class="mt-4">
                    <v-col cols="12" align="end">
                        <v-btn :disabled="invalid" @click="createPD_validator" class="success" rounded>
                            Gravar<v-icon class="ml-2">mdi-content-save</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>
        <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
            <InputEditorModal @change-value="changeEditorModal" vid="editor_local" :title="'Local'" :label="'Local'" v-model="inputEditorModalData.itemModel"/>
        </v-dialog>

        <ErrorSnackbar v-model="error" :timeout="timeout" :message="error_message" @input="timeout = 2000;"/>

        <SuccessSnackbar message="Dados gravados!" v-model="success"/>
        
        <Loader v-if="loader"/>

        <ConfirmModal @yes="createPD" @close-modal="confirmationModal.show = false" v-show="confirmationModal.show" :title="confirmationModal.title" :message="confirmationModal.message"/>
    </v-container>
</template>
<script>
import DateInput from "@/components/UI/Inputs/DateInput"
import InputEditorModal from "@/components/UI/Modals/InputEditorModal";
import Obra from "@/api/Obra.js"
import Equipamentos from "@/api/Equipamentos.js"
import FrenteObra from "@/api/FrenteObra.js"
import PDEq from "@/api/PDEq.js"
import Classe from "@/api/Classe.js"
import dayjs from 'dayjs'
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import ConfirmModal from '@/components/Modals/ConfirmModal.vue';
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import Loader from "@/components/UI/Loaders/Loader";
import MiniAlerts from '@/components/Alerts/MiniAlerts.vue';
import Tooltip from '@/components/UI/Tooltip.vue';

export default{
    name:"Operator",
    components:{
        DateInput,
        InputEditorModal,
        ErrorSnackbar,
        ConfirmModal,
        SuccessSnackbar,
        Loader,
        MiniAlerts,
        Tooltip
    },
    props:{
        method: String,
        diary: Object
    },
    watch: { 
        diary: {
            immediate: true, 
            handler (val) {
                if(!val) {
                    return;
                }
        
                let data = {...val};
        
                this.fields = data;

                this.line = this.fields.linhas;

                this.line.forEach(element => {

                    element.ho = isNaN(element.ho) ? 0 : parseFloat(element.ho).toFixed(2)
                    element.hs = isNaN(element.hs) ? 0 : parseFloat(element.hs).toFixed(2)
                    element.ha = isNaN(element.ha) ? 0 : parseFloat(element.ha).toFixed(2)
                    element.km = isNaN(element.km) ? 0 : parseFloat(element.km).toFixed(2)
                    element.h = isNaN(element.hi) ? 0 : parseFloat(element.hi).toFixed(2)
                    element.h = isNaN(element.h) ? 0 : parseFloat(element.h).toFixed(2)
                    element.hn = isNaN(element.hn) ? 0 : parseFloat(element.hn).toFixed(2)
                    element.hext = isNaN(element.hext) ? 0 : parseFloat(element.hext).toFixed(2)
                    element.diesel = isNaN(element.diesel) ? 0 : parseFloat(element.diesel).toFixed(2)
                    element.oil = isNaN(element.oil) ? 0 : parseFloat(element.oil).toFixed(2)
                    element.adblue = isNaN(element.adblue) ? 0 : parseFloat(element.adblue).toFixed(2)


                    if(element.ho == "NaN")
                        element.ho = 0

                    if(element.hs == "NaN")
                        element.hs = 0

                    if(element.ha == "NaN")
                        element.ha = 0

                    if(element.km == "NaN")
                        element.km = 0

                    if(element.h == "NaN")
                        element.h = 0

                    if(element.hn == "NaN")
                        element.hn = 0

                    if(element.hext == "NaN")
                        element.hext = 0

                    if(element.diesel == "NaN")
                        element.diesel = 0

                    if(element.oil == "NaN")
                        element.oil = 0

                    if(element.adblue == "NaN")
                        element.adblue = 0

                });


                this.loadingContent = 0

                this.changeWork()

                if(this.fields.frente_id == null)
                    this.hideFrentes = true

                if(this.fields.frente_id == null && this.fields.classe_id != null){
                    this.classesPorObra()
                    this.changeClasse()
                    this.classeNeeded = true
                }

                if(this.fields.classe_id != null){
                    this.classesPorObra()
                    this.changeClasse()
                    this.classeNeeded = true
                }

                this.showContent = true
            }
        },
    },
    data(){
        return{
            menu2: false,
            classeNeeded: false,
            confirmationModal: {
                show: false,
                title: '',
                message: ''
            },
            alert:{
                showAlert: false,
                message: ''
            },
            loadingContent: 2,
            hideFrentes: false,
            error: false,
            timeout: 2000,
            error_message: "",
            showContent: false,
            editorModal:false,
            success: false,
            loader: false,
            fields:{
                obra_id: null,
                frente_id: null,
                classe_id: null,
                Data: null
            },
            deletedLines: [],
            line: [
                {
                    id: dayjs().unix() + '-new',
                    accessory: false,
                    equip: null,
                    ho: parseFloat(0),
                    hs: parseFloat(0),
                    ha: parseFloat(0),
                    km: parseFloat(0),
                    hi: parseFloat(0),
                    h: parseFloat(0),
                    local: null,
                    user: null,
                    hn: parseFloat(8),
                    hext: parseFloat(0),
                    diesel: parseFloat(0),
                    oil: 0,
                    adblue: 0,
                    options: ""
                }
            ],
            obras: [],
            equipamentos: [],
            unassignedMachines: [],
            classes:[],
            frentes_obra: [],
            inputEditorModalData:{
                itemModel:null,
                id: null,
            },   
            rules_error_messages:{
                confirmation_message: 'Preencheu o formulario e está prestes a submeter o mesmo. \nTem a certeza que preencheu tudo bem? \n\nPretende prosseguir?',
                rule0: 'Há campos por preencher. Campos obrigatórios: HO, HS, HA, KM, H, HN e HExt',
                rule1: 'Horas normais + Horas extra devem ser superiores ou iguais a zero',
                rule2: 'As horas normais do equipamento não devem ser superiores ás horas de trabalho',
                rule3: 'Horas extra devem ser sempre iguais ou superiores às da linha anterior',
                rule4: 'Horas normais devem ser inferiores a 24',
                rule5: 'Campo dos KM ou Horas deve ser preenchido',
                rule6: 'Tem valores a 0 (zero). \n\nTenha atenção e confira se pretende mesmo que estes dados sejam registados. \n\nPretende prosseguir?'
            }     
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{        
        dataChanged(){
            if(this.obras.length == 1){
                this.fields.obra_id = this.obras[0].Id
                this.changeWork()
            }
        },
        checkNew(item){
            let aux = '' + item.id
            return aux.includes('new')
        },
        getVehicleName(item){
            return item.EquiEntityKey + ' - ' + item.EquipDescricao
            //let name = ''
            /*if(item.accessory){
                this.unassignedMachines.forEach(element => {
                    if(element.Id == item.equip)
                        name = element.EntityKey + ' - ' + element.Descricao
                });
            }else{
                this.equipamentos.forEach(element => {
                    if(element.Id == item.equip)
                        name = element.EntityKey + ' - ' + element.Descricao
                });
            }
            return name*/
        },
        disableBox(item){
            if(this.method == 'create')
                return false

            if(item.id != undefined)
                if(item.id.includes('new'))
                    return false


            return true
        },
        deleteLine(id){
            if(this.line.length == 1){
                this.error_message = "Não pode apagar a única linha que tem"
                this.error = true
                return
            }
                
            let index = this.line.findIndex(item => item.id == id);

            this.line.splice(index, 1);

            if(!id.includes('new'))
                this.deletedLines.push(id)
        },
        createPD(){
            this.loader = true;

            this.confirmationModal.show = false


            let lineItems = []
            let i = 1;
            this.line.forEach(l => {
                let variavel = null
                if (typeof l.equip === 'string') {
                    variavel = l.equip.trim()

                    if(!l.accessory){
                        this.getEquipamentos.forEach(element => {
                            if(element.EntityKey == variavel)
                                variavel = element.Id
                        });
                    }else{
                        this.unassignedMachines.forEach(element => {
                            if(element.EntityKey == variavel)
                                variavel = element.Id
                        });
                    }

                }else{
                    variavel = l.equip.Id
                }

                

                lineItems.push({
                    IdEquipamento: variavel,
                    accessory: l.accessory,
                    NumLinha: i,
                    local: l.local,
                    Servico: l.hs,
                    Ordem: l.ho,
                    Avariado: l.ha,
                    Contador2: l.km,
                    HoraInicial: l.hi,
                    Contador1: l.h,
                    HNormal: l.hn,
                    HExtra: l.hext,
                    diesel: l.diesel,
                    oil: l.oil,
                    adblue: l.adblue,
                    lineID: l.id
                })
                i++
            });


            // Obter a frente de obra
            let frenteObra = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frenteObra = element
            });
            // Obter obra
            let obra = null
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id)
                    obra = element
            })
            // Obter a classe
            let classe = null
            this.classes.forEach(element => {
                if(element.ID == this.fields.classe_id)
                    classe = element
            });

            let data = {
                SubEmpId: frenteObra == null ? null : frenteObra.SubEmpId,
                classeID: frenteObra == null ? null : frenteObra.SubEmp,
                hash: this.method == 'create' ? null : this.$route.params.hash,
                Codigo: obra.Codigo,
                Data: this.fields.Data,
                line: lineItems,
                classeID_manual: null,
                deletedLines: this.deletedLines
            };

            if(data.classeID == null){
                if(this.fields.classe_id == null){
                    this.error_message = 'Esta obra não tem uma classe associada. Para criar a diária é preciso ter, no mínimo, uma classe a ela associada'
                    this.timeout = 10000
                    this.error = true
                    this.loading = false
                    return
                }

                data.classeID_manual = classe.ClasseId
            }

            PDEq[this.method == 'create' ? 'create' : 'updatePD'](data, this.$route.params.hash).then((resp) => {
                let response = resp.data
                if(response.success == false){
                    this.error_message = response.error
                    this.error = true
                    this.timeout = 5000
                    this.loader = false;

                    return;
                }
                this.success = true;

                this.error = false;

                this.loader = false;

                //this.$router.push('/diarios/v2')
                
                if(this.method == 'create'){
                    let link = resp.data
                    this.$router.push("/diarios/manobrador/" + link.hash + "/" + link.creator + '/eq');
                }else
                    window.location.reload()
            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.loader = false

                this.error = true;

                this.error_message = err.response.data.message;

                this.loading = false;
            });
            
        },
        createPD_validator(){
            let validation = this.validateFormData()
            
            if(validation.has_error){
                this.alert.showAlert = true 
                setTimeout(() => {
                    this.alert.showAlert = false 
                }, "10000")

                return
            }

            if(validation.has_valores_zero){
                this.confirmationModal.show = true
                this.confirmationModal.title = 'Tem a certeza?'
                this.confirmationModal.message = this.rules_error_messages.rule6
            }else{
                this.confirmationModal.show = true
                this.confirmationModal.title = 'Tem a certeza?'
                this.confirmationModal.message = this.rules_error_messages.confirmation_message                
            }
        },
        validateFormData(){
            let errors = []
            let last_lower_value = 0
            let has_error = false
            let line = 1
            this.alert.message = ''
            let has_valores_zero = false
            this.line.forEach(l => {
                if(l.accessory == false){
                    let stepper = []

                    // Verificar se há valores a zero
                    if(
                        l.km == 0 ||
                        l.ho == 0 ||
                        l.hs == 0 || 
                        l.ha == 0 || 
                        l.h == 0  || 
                        l.hn == 0 || 
                        l.hext == 0 ||
                        l.diesel == 0
                    ){
                        has_valores_zero = true
                    }

                    l.ho = l.ho.toString()
                    l.hs = l.hs.toString()
                    l.ha = l.ha.toString()
                    l.h = l.h.toString()
                    l.hn = l.hn.toString()
                    l.hext = l.hext.toString()
                    l.diesel = l.diesel.toString()

                    // Rule N# 0 - Campos por preencher
                    if(
                        l.ho == '' || l.ho == null ||
                        l.hs == '' || l.hs == null ||
                        l.ha == '' || l.ha == null ||
                        l.h == ''  || l.h == null ||
                        l.hn == '' || l .hn == null ||
                        l.hext == '' || l.hext == null ||
                        l.diesel == '' || l.diesel == null
                    ){

                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule0 + "\n\n";
                    }else{
                        stepper.push(0)
                    }

                    l.ho = parseFloat(l.ho)
                    l.hs = parseFloat(l.hs)
                    l.ha = parseFloat(l.ha)
                    l.h = parseFloat(l.h)
                    l.hn = parseFloat(l.hn)
                    l.hext = parseFloat(l.hext)
                    l.diesel = parseFloat(l.diesel)

                    // Rule N# 1 - Normal Hours + Extra hours (HN+HE) should be ≥ 0;
                    if((l.hn + l.hext) < 0){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule1 + "\n\n";
                    }else{
                        stepper.push(0)
                    }

                    // Rule N# 2 -  The system should detect if the Normal hours of the driver is lower than the
                    //              equipments working hours. The equipment working hours is never superior of
                    //              the drivers. Total hours of the machine (HO+HS+HA) cannot be superior of
                    //              the drivers (HN+HE).
                    if((parseFloat(l.ho)+parseFloat(l.hs)+parseFloat(l.ha)) > (parseFloat(l.hn)+parseFloat(l.hext))){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule2 + "\n\n";
                    }else{
                        stepper.push(0)
                    }   
                        
                    // Rule N# 3 - The system must detect if the machines hours is less than the previous made diary.
                    if(l.hext < last_lower_value){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule3 + "\n\n";
                    }else{
                        stepper.push(0)
                        last_lower_value = l.hext
                    }

                    // Rule N# 4 - Normal Hours (HN) (The value should not be higher than 24)
                    if(l.hn > 24){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule4 + "\n\n";
                    }else{
                        stepper.push(0)
                    }

                    l.km = l.km.toString()


                    // Rule N# 5 -  The field “km or hours” must be filled.
                    if(l.km == '' || l.km == null){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule5 + "\n\n";
                    }else{
                        stepper.push(0)
                    }

                    l.km = parseFloat(l.km)


                    line++
                    errors.push(stepper)
                }
                
            });
            return {
                has_error: has_error,
                has_valores_zero: has_valores_zero
            }
        },
        changeFrenteObra(){
            if(this.fields.obra_id == null)
                return

            if(this.fields.frente_id == null)
                return

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            if(frente != null){
                if(frente.classes.length > 0){
                    this.classes = frente.classes
                    this.classeNeeded = true
                }else 
                    this.showContent = true
            }
            
        },
        addAccessoryLine(){
            let lastLine = this.line[this.line.length-1];

            this.line.push({
                id: 'new-'+dayjs().unix().toString(),
                accessory: true,
                equip: null,
                ho: lastLine.ho,
                hs: lastLine.hs,
                ha: lastLine.ha,
                km: lastLine.km,
                hi: lastLine.hi == undefined ? 0 : lastLine.hi,
                h: lastLine.h,
                local: null,
                user: null,
                hn: null,
                hext: null,
                diesel: null,
                oil: null,
                adblue: null,
                options: ""
            })
        },
        addLineToTable(){
            this.line.push({
                id: 'new-' + dayjs().unix(),
                accessory: false,
                equip: null,
                ho: parseFloat(0),
                hs: parseFloat(0),
                ha: parseFloat(0),
                km: parseFloat(0),
                hi: parseFloat(0),
                h: parseFloat(0),
                local: null,
                user: null,
                hn: parseFloat(8),
                hext: parseFloat(0),
                diesel: parseFloat(0),
                oil: 0,
                adblue: 0,
                options: ""
            })
        }, 
        changeEditorModal(e){
            this.line.forEach(element => {
                if(element.id == this.inputEditorModalData.id)
                    element.local = e
            });
        },
        openEditorModal(item){
            this.inputEditorModalData.itemModel = item.local
            this.inputEditorModalData.id = item.id
            this.editorModal=true
        },
        async fillBaseData(){
            let data = {
                tipo: 'O'
            }
            
            await Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
            });
        },
        changeWork(){
            let data = {
                work_id: this.fields.obra_id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'manobrador'
            }

            let ObraPai = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id)
                    ObraPai = element.Codigo
            });

            FrenteObra.all(ObraPai).then((resp) =>  {
                this.frentes_obra = resp.data
                    console.log(this.frentes_obra)

                if(this.frentes_obra.length == 0){
                    this.classeNeeded = true  
                    this.classesPorObra()
                }else{
                    if(this.frentes_obra.length == 1){
                        this.fields.frente_id = this.frentes_obra[0].ID

                        this.changeFrenteObra()
                    }
                }
            })

            Obra.getObraEquipamentos(data).then((resp)  =>  {
                // Manobrador acede apenas a equipamentos externos
                this.equipamentos = resp.data.externo
                this.loadingContent++
            })

            Equipamentos.getUnassigned(this.fields.obra_id).then((resp)    =>  {
                this.unassignedMachines = resp.data
                this.loadingContent++
            })

            

        },
        classesPorObra(){
            // Também temos de verificar se esta obra tem classes
            Classe.getClassesByObraPai(this.fields.obra_id).then((resp) =>  {
                this.classes = resp.data
                
                if(this.classes.length == 1){
                    this.fields.classe_id = this.classes[0].ID

                    this.changeClasse()
                }
            })
        },
        changeClasse(){
            if(this.fields.obra_id == null)
                return
            
            if(this.fields.classe_id == null)
                return

            this.showContent = true
        }
    },
    computed:{
        getLines(){
            return this.line
        },
        getEquipamentos(){
            let returner = []
            this.equipamentos.forEach(equipamento => {
                let existe = false
                this.line.forEach(linha => {
                    if(linha.equip != null)
                    if(equipamento.Id == linha.equip.Id)
                        existe = true
                });
                if(existe == false)
                    returner.push(equipamento)                
            });

            return returner
        }
    }
}
</script>
<style>
.v-text-field__slot label,input{
    font-size: 13px !important;
}

.v-list-item__title{
    font-size: 13px !important;
}

.v-select__slot label,input{
    font-size: 13px !important;
}
</style>