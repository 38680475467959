<template>
    <v-container fluid class="ma-0 pa-0">
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit" :disabled="true" style="zoom: 85%;">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-menu
                            v-if="method == 'create'"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                class="d-none d-lg-block"
                                v-model="fields.Data"
                                label="Insira Data"
                                outlined dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            <v-text-field 
                                class="d-block d-lg-none" 
                                v-model="fields.Data" 
                                type="date" 
                                id="birthday" 
                                label="Insira data"
                                dense outlined
                                :max="new Date(new Date()+1).toISOString().substr(0, 10)"
                            />
                            
                            </v-date-picker>
                            </template>
                            <v-date-picker
                                :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.Data"
                                @input="menu2 = false"
                                @change="dataChanged"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                            v-else
                            dense outlined
                            v-model="fields.Data"
                            disabled
                            label="Data"
                        />
                    </v-col>
                    <v-col cols="12" md="4" v-if="fields.Data != null">
                        <validation-provider v-slot="{ errors }" vid="Obras" name="Obras" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.obra_id"
                                :items="obras"
                                outlined dense
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                item-value="Id"
                                :error-messages="errors"
                                label="Obras *"
                                @change="changeWork"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3" v-if="false">
                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.frente_id"
                                :items="frentes_obra"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Frentes de Obra *"
                                @change="changeFrenteObra"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3" v-if="false">
                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.classe_id"
                                :items="classes"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Classe *"
                                @change="changeClasse"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>                    
                    <v-col cols="12" md="2">
                        <v-btn class="success" @click="confirmationModal.show = true">
                            Aprovar tudo
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="showContent" class="ma-0 pa-0 mt-4">
                    <v-col cols="12" class="ma-0 pa-0">
                        <!-- Mão Obra Interna -->
                        <v-row>
                            <v-col cols="12">
                                <v-card class="elevation-0">
                                    <v-card-title class="d-none d-lg-block warning white--text">
                                        Mão Obra Interna
                                    </v-card-title>
                                    <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                                        Mão Obra Interna
                                    </v-card-title>
                                    <v-card-text>
                                        <v-simple-table class="d-none d-lg-block" fixed-header>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th width="3%"></th>
                                                        <th class="text-left" width="15%">
                                                            Nome
                                                        </th>
                                                        <th class="text-left" width="15%">
                                                            Frente Obra
                                                        </th>
                                                        <th class="text-left" width="15%">
                                                            Classe
                                                        </th>
                                                        <th class="text-left" width="7%">
                                                            HN
                                                        </th>
                                                        <th class="text-left" width="7%">
                                                            HExt
                                                        </th>
                                                        <th class="text-left" width="5%">
                                                            Nota
                                                        </th>
                                                        <th width="10%">
                                                            Criador
                                                        </th>
                                                        <th class="text-left" width="10%">
                                                            Opções
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in computeFuncionariosInternos" :key="index+10001"
                                                    >
                                                        <td>
                                                            <v-icon v-if="lineTotalApproved(item)" color="success">mdi-check-decagram</v-icon>
                                                            <span v-else>{{lineApprovalState(item)}}</span>
                                                        </td>
                                                        <td>
                                                            <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario" name="funcionario" rules="required">
                                                                <v-autocomplete
                                                                    class="mt-4"
                                                                    outlined dense
                                                                    :items="funcionarios_internos"
                                                                    :item-text="item => item.Codigo + ' - ' + item.Nome"
                                                                    item-value="Codigo"
                                                                    label="Funcionário"
                                                                    :error-messages="errors"
                                                                    v-model="item.funcionario"
                                                                />
                                                            </validation-provider>
                                                            <v-text-field
                                                                v-else
                                                                dense outlined
                                                                class="mt-4"
                                                                label="Funcionario"
                                                                :disabled="true"
                                                                :value="item.funcionario + ' - ' + item.Nome"
                                                            />
                                                        </td>
                                                        <td>
                                                            <div v-if="showFrente(item)">
                                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                    <v-autocomplete                                                                                
                                                                        v-model="item.frente_id"
                                                                        :items="frentes_obra"
                                                                        outlined dense
                                                                        class="mt-4"
                                                                        :item-text="ob => ob.Descricao"
                                                                        item-value="ID"
                                                                        :error-messages="errors"
                                                                        label="Frentes de Obra *"
                                                                        @change="changeFrenteObraV2(item)"
                                                                    />
                                                                </validation-provider>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div v-if="showClasse(item)">
                                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                    <v-autocomplete
                                                                        v-model="item.classe_id"
                                                                        :items="item.available_classes"
                                                                        class="mt-4"
                                                                        outlined dense
                                                                        :item-text="ob => ob.Descricao"
                                                                        item-value="ID"
                                                                        :error-messages="errors"
                                                                        label="Classe *"
                                                                    />
                                                                </validation-provider>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <v-text-field 
                                                                class="mt-4"
                                                                label="HN"
                                                                outlined dense
                                                                placeholder="Insira HN"
                                                                v-model="item.hn"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field 
                                                                class="mt-4"
                                                                label="HExt"
                                                                outlined dense
                                                                placeholder="Insira HExt"
                                                                v-model="item.hext"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab x-small @click="openEditorModal(item, 'funcionario_interno')">
                                                                <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                                <v-badge
                                                                    v-else
                                                                    color="orange"
                                                                    dot
                                                                    overlap
                                                                >
                                                                    <v-icon>mdi-note-plus</v-icon>
                                                                </v-badge>
                                                            </v-btn>
                                                        </td>
                                                        <td >
                                                            <v-text-field
                                                                class="mt-4"
                                                                dense outlined
                                                                label="Criado por:"
                                                                :value="item.creator_code + ' - ' + item.creator_name"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-btn
                                                                fab
                                                                dark
                                                                x-small
                                                                outlined
                                                                color="warning"
                                                                @click="edit(item)"
                                                            >
                                                                <v-icon dark>
                                                                    mdi-pencil
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>

                                        <v-data-iterator
                                            :items="fields.funcionariosInternos"
                                            item-key="id"
                                            :items-per-page="-1"
                                            hide-default-footer
                                            class="d-block d-lg-none ma-0 pa-0"
                                        >
                                        <template v-slot:default="{ items }">
                                            <v-row class="ma-0 pa-0">
                                                <v-col
                                                    class="ma-0 pa-0"
                                                    v-for="(item, index) in items"
                                                    :key="index+200000"
                                                    cols="12"
                                                >
                                                    <v-card elevation="0" class="mt-2 rounded-lg ma-0 pa-0" outlined>
                                                        <v-list
                                                            dense
                                                        >
                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario" name="funcionario" rules="required">
                                                                        <v-autocomplete
                                                                            class="mt-4"
                                                                            outlined dense
                                                                            :items="funcionarios_internos"
                                                                            :item-text="item => item.Codigo + ' - ' + item.Nome"
                                                                            item-value="Codigo"
                                                                            label="Funcionário"
                                                                            :error-messages="errors"
                                                                            v-model="item.funcionario"
                                                                        />
                                                                    </validation-provider>
                                                                    <v-text-field
                                                                        v-else
                                                                        dense outlined
                                                                        class="mt-4"
                                                                        label="Funcionario"
                                                                        :disabled="true"
                                                                        :value="item.funcionario + ' - ' + item.Nome"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <div v-if="showFrente(item)">
                                                                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                            <v-autocomplete                                                                                
                                                                                v-model="item.frente_id"
                                                                                :items="frentes_obra"
                                                                                outlined dense
                                                                                class="mt-4"
                                                                                :item-text="ob => ob.Descricao"
                                                                                item-value="ID"
                                                                                :error-messages="errors"
                                                                                label="Frentes de Obra *"
                                                                                @change="changeFrenteObraV2(item)"
                                                                            />
                                                                        </validation-provider>
                                                                    </div>
                                                                    <span v-else>{{item.frente_obra == null ? '-' : item.frente_obra}}</span>
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <div v-if="showClasse(item)">
                                                                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                            <v-autocomplete
                                                                                v-model="item.classe_id"
                                                                                :items="item.available_classes"
                                                                                class="mt-4"
                                                                                outlined dense
                                                                                :item-text="ob => ob.Descricao"
                                                                                item-value="ID"
                                                                                :error-messages="errors"
                                                                                label="Classe *"
                                                                                @change="changeClasse"
                                                                            />
                                                                        </validation-provider>
                                                                    </div>
                                                                    <span v-else>{{item.classe_obra == null ? '-' : item.classe_obra}}</span>
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-text-field 
                                                                        class="mt-4"
                                                                        label="HN"
                                                                        outlined dense
                                                                        placeholder="Insira HN"
                                                                        v-model="item.hn"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-text-field 
                                                                        class="mt-4"
                                                                        label="HExt"
                                                                        outlined dense
                                                                        placeholder="Insira HExt"
                                                                        v-model="item.hext"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="openEditorModal(item, 'funcionario_interno')">
                                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                                        <v-badge
                                                                            v-else
                                                                            color="orange"
                                                                            dot
                                                                            overlap
                                                                        >
                                                                            <v-icon>mdi-note-plus</v-icon>
                                                                        </v-badge>
                                                                    </v-btn>
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-text-field
                                                                        class="mt-4"
                                                                        dense outlined
                                                                        label="Criado por:"
                                                                        :value="item.creator_code + ' - ' + item.creator_name"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>


                                                            <v-list-item >
                                                                <v-list-item-content class="align-center">
                                                                    <v-btn
                                                                        v-if="false"
                                                                        dark
                                                                        small
                                                                        color="error"
                                                                        @click="deleteFuncionarioInterno(item.id)"
                                                                    >
                                                                        <v-icon small>mdi-delete</v-icon> Remover
                                                                    </v-btn>                                                                    
                                                                    <v-btn
                                                                        fab
                                                                        dark
                                                                        x-small
                                                                        outlined
                                                                        color="warning"
                                                                        @click="edit(item)"
                                                                    >
                                                                        <v-icon dark>
                                                                            mdi-pencil
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        </v-data-iterator>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <!-- Mão Obra Interna -->

                        <!-- Mão Obra Externa -->
                        <v-row>
                            <v-col cols="12">
                                <v-card class="elevation-0">
                                    <v-card-title class="d-none d-lg-block warning white--text">
                                        Mão Obra Externa
                                    </v-card-title>
                                    <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                                        Mão Obra Externa
                                    </v-card-title>
                                    <v-card-text>
                                        <v-simple-table class="d-none d-lg-block" fixed-header>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th width="3%">
                                                        </th>
                                                        <th class="text-left" width="10%">
                                                            Entidade
                                                        </th>
                                                        <th class="text-left" width="15%">
                                                            Nome
                                                        </th>
                                                        <th class="text-left" width="15%">
                                                            Frente Obra
                                                        </th>
                                                        <th class="text-left" width="15%">
                                                            Classe
                                                        </th>
                                                        <th class="text-left" width="7%">
                                                            HN
                                                        </th>
                                                        <th class="text-left" width="7%">
                                                            HExt
                                                        </th>
                                                        <th class="text-left" width="5%">
                                                            Nota
                                                        </th>
                                                        <th width="10%">
                                                            Criador
                                                        </th>
                                                        <th class="text-left" width="10%">
                                                            Opções
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in computeFuncionariosExternos" :key="index + 800000"
                                                        class="mt-4"
                                                    >
                                                        <td>
                                                            <v-icon v-if="lineTotalApproved(item)" color="success">mdi-check-decagram</v-icon>
                                                            <span v-else>{{lineApprovalState(item)}}</span>
                                                        </td>
                                                        <td>
                                                            <v-text-field 
                                                                class="mt-4"
                                                                v-if="item.entidade != null"
                                                                readonly
                                                                dense outlined
                                                                :value="item.entidade.Codigo + ' - ' + item.entidade.Nome"
                                                                label="Entidade"
                                                            />
                                                        </td>
                                                        <td>
                                                            <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario_externo" name="funcionario_externo" rules="required">                                                                   
                                                                <v-autocomplete
                                                                    class="mt-4"
                                                                    outlined dense
                                                                    :items="funcionarios_externos"
                                                                    :item-text="item => item.Numero + ' - ' + item.Nome"
                                                                    item-value="Numero"
                                                                    label="Funcionário"
                                                                    :error-messages="errors"
                                                                    v-model="item.funcionario"
                                                                    @change="changedExterno"
                                                                />
                                                            </validation-provider>
                                                            <v-text-field
                                                                v-else
                                                                dense outlined
                                                                class="mt-4"
                                                                label="Funcionario"
                                                                :disabled="true"
                                                                :value="item.funcionario + ' - ' + item.Nome"
                                                            />
                                                        </td>
                                                        <td>
                                                            <div v-if="showFrente(item)">
                                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                    <v-autocomplete                                                                                
                                                                        v-model="item.frente_id"
                                                                        :items="frentes_obra"
                                                                        outlined dense
                                                                        class="mt-4"
                                                                        :item-text="ob => ob.Descricao"
                                                                        item-value="ID"
                                                                        :error-messages="errors"
                                                                        label="Frentes de Obra *"
                                                                        @change="changeFrenteObraV2(item)"
                                                                    />
                                                                </validation-provider>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div v-if="showClasse(item)">
                                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                    <v-autocomplete
                                                                        v-model="item.classe_id"
                                                                        :items="item.available_classes"
                                                                        class="mt-4"
                                                                        outlined dense
                                                                        :item-text="ob => ob.Descricao"
                                                                        item-value="ID"
                                                                        :error-messages="errors"
                                                                        label="Classe *"
                                                                    />
                                                                </validation-provider>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <v-text-field 
                                                                class="mt-4"
                                                                outlined dense
                                                                label="HN"
                                                                placeholder="Insira HN"
                                                                v-model="item.hn"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field 
                                                                class="mt-4"
                                                                outlined dense
                                                                label="HExt"
                                                                placeholder="Insira HExt"
                                                                v-model="item.hext"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab x-small @click="openEditorModal(item, 'funcionario_externo')">
                                                                <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                                <v-badge
                                                                    v-else
                                                                    color="orange"
                                                                    dot
                                                                    overlap
                                                                >
                                                                    <v-icon>mdi-note-plus</v-icon>
                                                                </v-badge>
                                                            </v-btn>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                class="mt-4"
                                                                dense outlined
                                                                label="Criado por:"
                                                                :value="item.creator_code + ' - ' + item.creator_name"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-btn
                                                                fab
                                                                dark
                                                                x-small
                                                                outlined
                                                                color="warning"
                                                                @click="edit(item)"
                                                            >
                                                                <v-icon dark>
                                                                    mdi-pencil
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>

                                        <v-data-iterator
                                            :items="fields.funcionariosExternos"
                                            item-key="id"
                                            :items-per-page="-1"
                                            hide-default-footer
                                            class="d-block d-lg-none"
                                        >
                                        <template v-slot:default="{ items }">
                                            <v-row>
                                                <v-col
                                                    v-for="(item, index) in items"
                                                    :key="index+30000"
                                                    cols="12"
                                                >
                                                    <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                                                        <v-list
                                                            dense
                                                        >
                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-text-field 
                                                                        class="mt-4"
                                                                        v-if="item.entidade != null"
                                                                        readonly
                                                                        dense outlined
                                                                        :value="item.entidade.Codigo + ' - ' + item.entidade.Nome"
                                                                        label="Entidade"
                                                                    />
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario_externo" name="funcionario_externo" rules="required">                                                                   
                                                                        <v-autocomplete
                                                                            class="mt-4"
                                                                            outlined dense
                                                                            :items="funcionarios_externos"
                                                                            :item-text="item => item.Numero + ' - ' + item.Nome"
                                                                            item-value="Numero"
                                                                            label="Funcionário"
                                                                            :error-messages="errors"
                                                                            v-model="item.funcionario"
                                                                            @change="changedExterno"
                                                                        />
                                                                    </validation-provider>
                                                                    <v-text-field
                                                                        v-else
                                                                        dense outlined
                                                                        class="mt-4"
                                                                        label="Funcionario"
                                                                        :disabled="true"
                                                                        :value="item.funcionario + ' - ' + item.Nome"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <div v-if="showFrente(item)">
                                                                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                            <v-autocomplete                                                                                
                                                                                v-model="item.frente_id"
                                                                                :items="frentes_obra"
                                                                                outlined dense
                                                                                class="mt-4"
                                                                                :item-text="ob => ob.Descricao"
                                                                                item-value="ID"
                                                                                :error-messages="errors"
                                                                                label="Frentes de Obra *"
                                                                                @change="changeFrenteObraV2(item)"
                                                                            />
                                                                        </validation-provider>
                                                                    </div>
                                                                </v-list-item-content>
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <div v-if="showClasse(item)">
                                                                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                            <v-autocomplete
                                                                                v-model="item.classe_id"
                                                                                :items="item.available_classes"
                                                                                class="mt-4"
                                                                                outlined dense
                                                                                :item-text="ob => ob.Descricao"
                                                                                item-value="ID"
                                                                                :error-messages="errors"
                                                                                label="Classe *"
                                                                            />
                                                                        </validation-provider>
                                                                    </div>
                                                                </v-list-item-content>
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-text-field 
                                                                        class="mt-4"
                                                                        label="HN"
                                                                        outlined dense
                                                                        placeholder="Insira HN"
                                                                        v-model="item.hn"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-text-field 
                                                                        class="mt-4"
                                                                        label="HExt"
                                                                        outlined dense
                                                                        placeholder="Insira HExt"
                                                                        v-model="item.hext"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="openEditorModal(item, 'funcionario_externo')">
                                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                                        <v-badge
                                                                            v-else
                                                                            color="orange"
                                                                            dot
                                                                            overlap
                                                                        >
                                                                            <v-icon>mdi-note-plus</v-icon>
                                                                        </v-badge>
                                                                    </v-btn>
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-text-field
                                                                        class="mt-4"
                                                                        dense outlined
                                                                        label="Criado por:"
                                                                        :value="item.creator_code + ' - ' + item.creator_name"
                                                                    />
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>

                                                            <v-list-item>
                                                                <v-list-item-content class="align-center">
                                                                    <v-btn
                                                                        v-if="false"
                                                                        dark
                                                                        small
                                                                        color="error"
                                                                        @click="deleteFuncionarioExterno(item.id)"
                                                                    >
                                                                        <v-icon small>mdi-delete</v-icon> Remover
                                                                    </v-btn>                                                                    
                                                                    <v-btn icon @click="edit(item)">
                                                                        <v-icon>mdi-eye</v-icon>
                                                                    </v-btn>
                                                                </v-list-item-content>                                                                            
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        </v-data-iterator>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <!-- Mão Obra Externa -->

                        <Equipments :aggregator="true" :obra_selecionada="obra_selecionada" :newLines="false" @nota="openEditorModal" :loadedClasses="classes" :hideFrentes="hideFrentes" :frentes_obra="frentes_obra" :fields="fields" return-object/>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row v-if="showContent">
                    <v-col cols="12" md="10" align="center" v-if="false">
                        <v-btn v-if="canSave == 4" rounded class="success mt-4" disabled>
                            Gravar
                        </v-btn>
                        <v-btn v-else rounded class="success mt-4" :disabled="invalid" type="submit">
                            Gravar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>

    <v-dialog
        v-model="entitySelect"
        fullscreen
    >
        <v-card class="elevation-0 ma-0 pa-0" v-if="entitySelect">
            <v-toolbar
                dark
                color="primary"
            >
                <v-toolbar-title>Funcionários por entidade</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn
                    dark
                    text
                    @click="entitySelect = false"
                >
                    X
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="mt-4 d-none d-lg-block">
                <v-row>
                    <v-col cols="12" md="6" v-if="hideFrentes == false" >
                        <v-autocomplete                                                                                                       
                            v-model="entitySelectedFrente"
                            :items="frentes_obra"
                            outlined dense
                            class="mt-4"
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Frentes de Obra *"
                            @change="changeFrenteObraV2Entity"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="entitySelectedClasse"
                            :items="entityClasses"
                            class="mt-4"
                            outlined dense
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Classe *"
                            @change="changeClasse"
                        />
                    </v-col>
                    <v-col cols="12">                        
                        <v-autocomplete
                            dense outlined
                            :items="entidades"
                            v-model="entitySelected"
                            :item-text="item=>item.Codigo + ' - ' + item.Nome"
                            item-value="ID"
                            label="Entidade"
                            @change="getFuncionariosPorEntidade"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        Pesquisa:
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            dense outlined
                            v-model="searchEntitySelected"
                            label="Nome/Número"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn
                            class="success"
                            @click="getFuncionariosPorEntidade"
                        ><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="mt-4 d-block d-lg-none">
                <v-row no-gutters>
                    <v-col cols="12">                        
                        <v-autocomplete
                            dense outlined
                            :items="entidades"
                            v-model="entitySelected"
                            :item-text="item=>item.Codigo + ' - ' + item.Nome"
                            item-value="ID"
                            label="Entidade"
                            @change="getFuncionariosPorEntidade"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-if="hideFrentes == false"                                                                            
                            v-model="entitySelectedFrente"
                            :items="frentes_obra"
                            outlined dense
                            class="mt-4"
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Frentes de Obra *"
                            @change="changeFrenteObraV2Entity"
                        />
                        <span v-else>-</span>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="entitySelectedClasse"
                            :items="entityClasses"
                            class="mt-4"
                            outlined dense
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Classe *"
                            @change="changeClasse"
                        />
                    </v-col>
                    <v-col cols="12" md="auto">
                        Pesquisa:
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field
                            dense outlined
                            v-model="searchEntitySelected"
                            label="Nome/Número"
                        />
                    </v-col>
                    <v-col cols="12" md="2" align="center" justify="center">
                        <v-btn
                            class="success"
                            @click="getFuncionariosPorEntidade"
                        ><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="entitySelected != null">
                <!-- DESKTOP -->
                <v-virtual-scroll
                    class="d-none d-lg-block"
                    :height="getWindowHeight" 
                    item-height="75"
                    :items="funcionariosPorEntidade">
                    <template v-slot:default="{ item }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-row>
                                <v-col cols="12" md="8">
                                    <v-text-field 
                                        dense outlined
                                        disabled
                                        v-model="item.funcionario"
                                        label="Funcionario"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-text-field 
                                        dense outlined
                                        v-model="item.hn"
                                        @keydown="item.selected = true"
                                        label="HN"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-text-field 
                                        dense outlined
                                        v-model="item.hext"
                                        @keydown="item.selected = true"
                                        label="HExt"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-text-field 
                                        dense outlined
                                        v-model="item.local"
                                        @keydown="item.selected = true"
                                        label="Nota"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-switch 
                                        dense
                                        v-model="item.selected"
                                    />
                                </v-col>
                            </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
                <!-- DESKTOP -->

                <!-- MOBILE -->
                <v-virtual-scroll 
                    v-if="funcionariosPorEntidade.length > 0" 
                    class="d-md-none"
                    height="600" 
                    item-height="475"
                    :items="funcionariosPorEntidade">
                    <template v-slot:default="{ item }">
                        <v-card class="elevation-0" outlined>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-row>
                                    <v-col cols="12" md="8">
                                        <v-text-field 
                                            dense outlined
                                            disabled
                                            v-model="item.funcionario"
                                            label="Funcionario"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-text-field 
                                            dense outlined
                                            v-model="item.hn"
                                            @keydown="item.selected = true"
                                            label="HN"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-text-field 
                                            dense outlined
                                            v-model="item.hext"
                                            @keydown="item.selected = true"
                                            label="HExt"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-text-field 
                                            dense outlined
                                            v-model="item.local"
                                            @keydown="item.selected = true"
                                            label="Nota"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-switch 
                                            dense
                                            v-model="item.selected"
                                        />
                                    </v-col>
                                </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </template>
                    <v-row>
                        <v-col cols="12">
                            <v-data-iterator
                                :items="funcionariosPorEntidade"
                                item-key="id"
                                :items-per-page="-1"
                                hide-default-footer
                                class="d-block d-lg-none ma-0 pa-0"
                            >
                            <template v-slot:default="{ items }">
                                <v-row class="ma-0 pa-0">
                                    <v-col
                                        class="ma-0 pa-0"
                                        v-for="(item, index) in items"
                                        :key="index+900000"
                                        cols="12"
                                    >
                                        <v-card elevation="0" class="mt-2 rounded-lg ma-0 pa-0" outlined>
                                            <v-list
                                                dense
                                            >
                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            disabled
                                                            v-model="item.funcionario"
                                                            label="Funcionario"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            v-model="item.hn"
                                                            @keydown="item.selected = true"
                                                            label="HN"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            v-model="item.hext"
                                                            @keydown="item.selected = true"
                                                            label="HExt"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            v-model="item.local"
                                                            @keydown="item.selected = true"
                                                            label="Nota"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-switch 
                                                            v-model="item.selected"
                                                            label="Selecionar"
                                                            inset
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>
                            </v-data-iterator>
                        </v-col>
                    </v-row>
                </v-virtual-scroll>
                <!-- MOBILE -->
            </v-card-text>
            <v-card-actions v-if="entitySelected != null">
                <v-row>
                    <v-col align="center" justify="center" cols="12">
                        <v-btn class="success" @click="addFuncionariosPorEntidade">Gravar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <SuccessSnackbar message="Colaboradores inseridos na diária" v-model="successAddEntity"/>

    <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
            <InputEditorModal @change-value="changeEditorModal" vid="editor_local" :title="'Local'" :label="'Local'" v-model="inputEditorModalData.itemModel"/>
        </v-dialog>
        

    <ErrorSnackbar v-model="error" :message="error_message"/>

    <Loader v-if="loader"/>

    <ConfirmModal @yes="approveAllLines" @close-modal="confirmationModal.show = false" v-show="confirmationModal.show" :title="confirmationModal.title" :message="confirmationModal.message"/>

    <FeedbackModal @close="reloadPage" title="Diárias aprovadas" :feedback="feedback.message" v-if="feedback.show"/>

    </v-container>
</template>
<script> 
import PDEnc from "@/api/PDEnc.js" 
import DateInput from "@/components/UI/Inputs/DateInput"
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import Obra from "@/api/Obra.js"
import FrenteObra from '@/api/FrenteObra.js'
import Entidades from '@/api/Entidades.js'
import Materiais from '@/api/Materiais.js'
import Classe from "@/api/Classe.js"
import Diaries from "@/api/Diaries.js"
import FeedbackModal from '@/components/UI/Modals/FeedbackModal.vue'
import dayjs from 'dayjs'
import Equipments from '@/components/Diaries/Supervisor/Objects/Equipments.vue'
/*import Materials from '@/components/Diaries/Supervisor/Objects/Materials.vue'
import Subcontractors from '@/components/Diaries/Supervisor/Objects/Subcontractors.vue'
import Observations from '@/components/Diaries/Supervisor/Objects/Observations.vue'*/
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Loader from "@/components/UI/Loaders/Loader";
import InputEditorModal from "@/components/UI/Modals/InputEditorModal";
import ConfirmModal from '@/components/Modals/ConfirmModal.vue';

export default{
    name:"Supervisor",
    components:{
        FeedbackModal,
        ConfirmModal,
        DateInput,
        Equipments,
        /*Materials,
        Subcontractors,
        Observations,*/
        ErrorSnackbar,
        Loader,
        SuccessSnackbar,
        InputEditorModal
    },
    props:{
        method: String,
        diary: null
    },
    watch: { 
        entitySelect:{
            immediate: true,
            handler(val){
                if(val == undefined)
                    return

                if(val == false)
                    this.entitySelected = null
            }
        },
        diary: {
            immediate: true, 
            async handler (val) {
                if(!val) {
                    return;
                }
        
                let data = {...val};
        
                this.fields = data;
                
                this.fields.funcionariosInternos = data.mao_obra.interno == null ? [] : data.mao_obra.interno

                this.fields.funcionariosInternos.forEach(element => {
                    element.hn = isNaN(element.hn) ? 0 : parseFloat(element.hn).toFixed(2)
                    element.hn = element.hn % 1 == 0 ? parseInt(element.hn) : element.hn

                    element.hext = isNaN(element.hext) ? 0 : parseFloat(element.hext).toFixed(2)
                    element.hext = element.hext % 1 == 0 ? parseInt(element.hext) : element.hext
                });

                this.fields.funcionariosExternos = data.mao_obra.externo == null ? [] : data.mao_obra.externo

                this.fields.funcionariosExternos.forEach(element => {
                    element.hn = isNaN(element.hn) ? 0 : parseFloat(element.hn).toFixed(2)
                    element.hn = element.hn % 1 == 0 ? parseInt(element.hn) : element.hn

                    element.hext = isNaN(element.hext) ? 0 : parseFloat(element.hext).toFixed(2)
                    element.hext = element.hext % 1 == 0 ? parseInt(element.hext) : element.hext
                });

                this.fields.equipamentosInternos = data.equipamento.interno == null ? [] : data.equipamento.interno

                this.fields.equipamentosInternos.forEach(element => {
                    element.hs = isNaN(element.hs) ? 0 : parseFloat(element.hs).toFixed(2)
                    element.hs = element.hs % 1 == 0 ? parseInt(element.hs) : element.hs

                    element.ho = isNaN(element.ho) ? 0 : parseFloat(element.ho).toFixed(2)
                    element.ho = element.ho % 1 == 0 ? parseInt(element.ho) : element.ho

                    element.hp = isNaN(element.hp) ? 0 : parseFloat(element.hp).toFixed(2)
                    element.hp = element.hp % 1 == 0 ? parseInt(element.hp) : element.hp

                    element.ha = isNaN(element.ha) ? 0 : parseFloat(element.ha).toFixed(2)
                    element.ha = element.ha % 1 == 0 ? parseInt(element.ha) : element.ha
                });

                this.fields.equipamentosExternos = data.equipamento.externo == null ? [] : data.equipamento.externo

                this.fields.equipamentosExternos.forEach(element => {
                    element.hs = isNaN(element.hs) ? 0 : parseFloat(element.hs).toFixed(2)
                    element.hs = element.hs % 1 == 0 ? parseInt(element.hs) : element.hs

                    element.ho = isNaN(element.ho) ? 0 : parseFloat(element.ho).toFixed(2)
                    element.ho = element.ho % 1 == 0 ? parseInt(element.ho) : element.ho                    

                    element.hp = isNaN(element.hp) ? 0 : parseFloat(element.hp).toFixed(2)
                    element.hp = element.hp % 1 == 0 ? parseInt(element.hp) : element.hp

                    element.ha = isNaN(element.ha) ? 0 : parseFloat(element.ha).toFixed(2)
                    element.ha = element.ha % 1 == 0 ? parseInt(element.ha) : element.ha
                });
                
                this.fields.materiais = data.materiais
                this.fields.subempreitadas = data.subempreitada

                this.fields.deleteMO =[];
                this.fields.deleteEQ =[];
                this.fields.deleteMaterial =[];
                this.fields.deleteSubempreitada =[];

                if(data.newObservationsClasses != null){
                    this.fields.obsMO = data.newObservationsClasses.obs_work_hand
                    this.fields.obsEQ = data.newObservationsClasses.obs_equipments
                }

                
                await this.getObras()

                this.changeWork()

                // Diária não tem frente mas tem unidade
                if(this.fields.frente_id == null && this.fields.classe_id != null){
                    this.classesPorObra()
                    this.changeClasse()
                }

                if(this.fields.classe_id != null){
                    this.changeClasse()
                }


                
                this.showContent = true
            }
        },
        loadUpdateMetadata:{
            immediate: true,
            handler(val){
                if(!val){
                    return;
                }

                this.changeFrenteObra()
            }
        }
    },
    data(){
        return{
            feedback:{
                show: false,
                message: ''
            },
            confirmationModal:{
                show: false,
                title: "Aprovar todas as linhas",
                message: "Tem a certeza que pretende aprovar todas as linhas?"
            },
            //Runner counters
            classesPorObraCounter: 0,
            entidadesPorObraCounter: 0,
            getFrenteObraCounter: 0,
            getObrasCounter: 0,
            getFuncionariosCounter: 0,
            // End runner counters
            successAddEntity: false,
            searchEntitySelected: null,
            funcionariosPorEntidade: [],
            entitySelected: null,
            entitySelectedFrente: null,
            entitySelect: false,
            entidades: [],
            menu2: false,
            tab: 0,
            userNaoExisteObra: false,
            hideFrentes: false,
            loadUpdateMetadata: false,
            loader: false,
            loading: true,
            error_message: null,
            error: false,
            obras: [],
            frentes_obra: [],
            classes: [],
            funcionarios_externos: [],
            funcionarios_internos: [],
            equipamentos: [],
            showContent: false,
            classeNeeded: false,
            entityClasses: [],
            entityClasseNeeded: false,
            entityHideFrentes: false,
            entitySelectedClasse: null,
            inputEditorModalData:{
                place: null,
                itemModel:null,
                id: null,
                classe_id: null,
                frente_id: null
            },   
            fields:{
                Data: null,
                obra_id: null,
                frente_id: null,
                classe_id: null,
                funcionariosInternos: [],
                funcionariosExternos: [],
                equipamentosInternos: [],
                equipamentosExternos: [],
                materiais:[],
                subempreitadas: [],
                obsMO: null,
                obsEQ: null,
                deleteMO: [],
                deleteEQ: [],
                deleteMaterial: [],
                deleteSubempreitada: []
            },
            editorModal:false,
            obra_selecionada: null
        }
    },
    mounted(){                
        this.fillBaseData() 
    },
    methods:{   
        lineApprovalState(item){            
            if(this.obra_selecionada == null)
                return null;

            let obra_nivel = this.obra_selecionada.approval_level == null ? 2 : this.obra_selecionada.approval_level
            return (item.approval == null ? 0 : item.approval) + '/' + obra_nivel
        },
        lineTotalApproved(item){  
            if(this.obra_selecionada == null)
                return false;

            if(item.approval == null)
                return false
                console.log(this.obra_selecionada)
                
            if(parseInt(this.obra_selecionada.obra.approval_level) == parseInt(item.approval))
                return true

            return false
        },
        approveAllLines(){
            let data = {
                data: this.fields.Data,
                obra_id: this.fields.obra_id
            }
            Diaries.approveAllLines(data).then((resp)   =>  {
                let data = resp.data

                let aux = 'A sua ação foi realizada com sucesso!<br/>Foram aprovadas ' + resp.data.length + ' linhas.<br/><br/><strong>Nota:</strong>O sistema só aprova as diárias de acordo com o role e nível de aprovação necessários';

                this.confirmationModal.show = false
                this.feedback.message = aux
                this.feedback.show = true
            })
        },
        edit(item){
            //this.$router.push("/diarios/encarregado/editar/" + item.webAppLinkHash + "/" + item.id_user);
            window.open(process.env.VUE_APP_API_URL + '/diarios/encarregado/editar/' + item.webAppLinkHash + "/" + item.id_user, '_blank')
        },
        openEditorModal(item, place){
            this.inputEditorModalData.itemModel = item.local
            this.inputEditorModalData.id = item.id
            this.inputEditorModalData.place = place
            this.inputEditorModalData.classe_id = item.classe_id
            this.inputEditorModalData.frente_id = item.frente_id

            this.editorModal=true
        },
        changeEditorModal(e){
            if(this.inputEditorModalData.place == 'funcionario_interno'){
                this.fields.funcionariosInternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id && element.classe_id == this.inputEditorModalData.classe_id && element.frente_id == this.inputEditorModalData.frente_id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'funcionario_externo'){
                this.fields.funcionariosExternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'equipamento_interno'){
                this.fields.equipamentosInternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'equipamento_externo'){
                this.fields.equipamentosExternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'material'){
                this.fields.materiais.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'subempreitada'){
                this.fields.subempreitadas.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
        },
        showFrente(item){
            if(this.$route.params.work_id != null && this.hideFrentes == false)
                return true

            return false
        },
        showClasse(item){
            if(item.classeNeeded)
                return true

            return false
        },
        addFuncionariosPorEntidade(){
                //entity_classes
                // frentes_obra

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.entitySelectedFrente)
                    frente = element.Descricao
            });
            let classe = null
            this.entityClasses.forEach(element => {
                if(element.ID == this.entitySelectedClasse)
                    classe = element.Descricao
            });
            this.funcionariosPorEntidade.forEach(element => {
                if(element.selected){
                    this.fields.funcionariosExternos.push({
                        id: element.id + '-new',
                        funcionario: element.numero,
                        hn: element.hn,
                        frente_id: this.entitySelectedFrente,
                        classe_id: this.entitySelectedClasse,
                        classe_obra: classe == null ? '-' : classe,
                        frente_obra: frente == null ? '-' : frente,
                        hext: element.hext,
                        local: element.local,
                        options: "",
                        entidade: element.entidade
                    })
                }
            });

            this.getFuncionariosPorEntidade()

            this.entitySelected = null
            this.entitySelect = false
            this.successAddEntity = true
        },
        changedExterno(val){
            let ent = null
            this.funcionarios_externos.forEach(element => {
                if(element.Numero == val){
                    ent = element.entidade
                }
            });

            let pos = -1
            let i = 0
            this.fields.funcionariosExternos.forEach(element => {
                if(element.Numero == val || element.funcionario == val)
                    pos = i

                i++
            });

            if(pos != -1)
                this.fields.funcionariosExternos[pos].entidade = ent
        },
        toEditRow(item){
            if(item.id.includes('new'))
                return true

            return false
        },
        dataChanged(){
            if(this.obras.length == 1){
                this.changeWork()
            }
        },
        changeClasse(){
            this.loading = true
            let object_data = {
                id: this.$route.params.work_id,
                frente: this.fields.frente_id,
                unidade: this.fields.classe_id,
                short_id: false,
                obra_id: this.$route.params.work_id
            }
            
            /*Obra.getObrasByClassFuncionarios(object_data).then((resp)  =>  {
                this.funcionarios_externos = resp.data.externo
                this.funcionarios_internos = resp.data.interno
            })*/


            this.showContent = true
            this.loading = false
        },
        async changeFrenteObra(){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }

                await Classe.getClasses(data).then((resp) =>  {
                    this.classes = resp.data

                    if(this.$store.getters.hasRole(['parque']))
                        this.tab = 1


                    if(this.classes.length == 1){
                        this.fields.classe_id = this.classes[0].ID
                        this.changeClasse()
                    }
                })

                if(this.classes.length == 0){
                    this.showContent = true
                    this.classeNeeded = false
                }else this.classeNeeded = true
            }

            this.loading = false
        },
        async changeFrenteObraV2(item){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == item.frente_id)
                    frente = element
            });


            let entrei = false
            if(frente != null){
                if(frente.classes != null){
                    if(frente.classes.length > 0){
                        entrei = true
                        item.available_classes = frente.classes
                        item.classeNeeded = true
                    }
                }

                if(entrei == false){
                    let data = {
                        SubEmp: frente.SubEmp
                    }


                    await Classe.getClasses(data).then((resp) =>  {
                        item.available_classes = resp.data

                        if(item.available_classes.length == 0){
                            item.classeNeeded = false
                        }else item.classeNeeded = true
                        
                    })
                }
                
            }

           
            
            this.loading = false
        },
        async changeFrenteObraV2Entity(){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.entitySelectedFrente)
                    frente = element
            });

            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }

                await Classe.getClasses(data).then((resp) =>  {
                    this.entityClasses = resp.data
                })

                if(this.entityClasses.length == 0){
                    this.entityClasseNeeded = false
                }else this.entityClasseNeeded = true
            }

            
            this.loading = false
        },
        changeWork(){
            this.loading = true

            let data = {
                work_id: this.$route.params.work_id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'encarregado'
            }

            
            this.getFrenteObra();

            this.getFuncionarios();

            this.loading = false


            this.entidadesPorObra()

            this.showContent = true
        },
        getFrenteObra(){
            if(this.getFrenteObraCounter > 0)
                return

            this.getFrenteObraCounter++

            let ObraPai = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.$route.params.work_id)
                    ObraPai = element.Codigo
            });

            FrenteObra.all(ObraPai).then((resp) =>  {
                this.frentes_obra = resp.data


                if(this.method=='update')
                    this.loadUpdateMetadata = true

                if(this.frentes_obra.length == 0)
                    this.classesPorObra()

                if(this.frentes_obra.length == 1){
                    this.fields.frente_id = this.frentes_obra[0].ID
                    this.changeFrenteObra()
                }
            })   
        },
        getFuncionarios(){
            if(this.getFuncionariosCounter > 0)
                return

            this.getFuncionariosCounter++
            let data = {
                id: this.$route.params.work_id,
                frente: null
            }

            Obra.getObraFuncionarios(data).then((resp)  =>  {
                this.funcionarios_internos = resp.data
            })

            Obra.getObraPessoal(this.$route.params.work_id).then((resp)    =>  {
                this.funcionarios_externos = resp.data
            })
        },
        entidadesPorObra(){
            if(this.entidadesPorObraCounter > 0)
                return 

            this.entidadesPorObraCounter++

            Entidades.byWork(this.$route.params.work_id).then((resp)   =>  {
                this.entidades = resp.data.entidades
            })
        },
        classesPorObra(){
            if(this.classesPorObraCounter > 0)
                return;

            this.classesPorObraCounter++
            // Também temos de verificar se esta obra tem classes
            Classe.getClassesByObraPai(this.$route.params.work_id).then((resp) =>  {
                this.classes = resp.data
                this.entityClasses = resp.data
                this.hideFrentes = true
                this.classeNeeded = true

                if(this.$store.getters.hasRole(['parque']))
                    this.tab = 1


                if(this.classes.length == 1){
                    this.fields.classe_id = this.classes[0].ID
                    this.changeClasse()
                }
               
            })
        },
        async getObras(){
            if(this.getObrasCounter > 0)
                return

            this.getObrasCounter++
            let data = {
                tipo: 'O'
            }
            
            await Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
                
                if(this.method == 'update')
                    this.changeWork()   

            });
        },
        async fillBaseData(){
            await this.getObras();

                  

            await Obra.getObra(this.fields.obra_id).then((resp)   =>  {
                this.obra_selecionada = resp.data
            })
                    
                
            this.loading = false
        },
        deleteFuncionarioInterno(id){
            let pos = -1    
            let i = 0
            this.fields.funcionariosInternos.forEach(element => {
                if(element.id == id){
                    this.fields.deleteMO.push(element.primary_id)
                    pos = i
                }

                i++
            });

            //this.fields.deleteMO.push(id)

            this.fields.funcionariosInternos.splice(pos, 1)
        },
        deleteFuncionarioExterno(id){
            let pos = -1    
            let i = 0
            this.fields.funcionariosExternos.forEach(element => {
                if(element.id == id){
                    this.fields.deleteMO.push(element.primary_id)
                    pos = i
                }

                i++
            });

            this.fields.funcionariosExternos.splice(pos, 1)
        },
        addFuncionarioInterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes == true){
                available_classes = this.classes;
                classeNeeded = true;
            }
            let tamanho = 0
            if(this.fields.funcionariosInternos != null)
                tamanho = this.fields.funcionariosInternos.length

            this.fields.funcionariosInternos.push({
                id: dayjs().unix() + '-' + tamanho + '-new',
                funcionario: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hn: parseFloat(8),
                hext: parseFloat(0),
                local: null,
                options: ""
            })
        },
        addFuncionarioExterno(){
            //this.entitySelect = false;
            //this.entitySelect = true;
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes == true){
                available_classes = this.classes;
                classeNeeded = true;
            }

            let tamanho = 0
            if(this.fields.funcionariosExternos != null)
                tamanho = this.fields.funcionariosExternos.length
            
            this.fields.funcionariosExternos.push({
                id: dayjs().unix() + '-' + tamanho + '-new',
                funcionario: null,
                frente_id: null,
                classe_id: null,
                funcionariosEntidades: [],
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hn: parseFloat(8).toFixed(2),
                hext: parseFloat(0).toFixed(2),
                local: null,
                options: ""
            })
        },
        submit(){
            this.loader = true;



            let obra = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.$route.params.work_id)
                    obra = element
            });



            let frente = null            
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            let classe = null
            this.classes.forEach(element => {
                if(element.ID == this.fields.classe_id)
                    classe = element
            });

            
            let data = {
                classeID: classe == null ? null : classe.ClasseId,
                id_user_creator: this.fields.id_user,
                SubEmpId: frente == null ? null : frente.SubEmpId,
                Codigo: obra.Codigo,
                classe: classe == null ? null : classe.ClasseId,    
                Data: this.fields.Data,
                maoObraInterna: this.fields.funcionariosInternos,
                maoObraExterna: this.fields.funcionariosExternos,
                equipamentoInterno: this.fields.equipamentosInternos,
                equipamentoExterno: this.fields.equipamentosExternos,
                material: this.fields.materiais,
                subEmpreitadas: this.fields.subempreitadas,
                obsMO: this.fields.obsMO,
                obsEQ: this.fields.obsEQ,
                hash: this.$route.params.hash,
                toDelete:{
                    material: this.fields.deleteMaterial,
                    equipment: this.fields.deleteEQ,
                    MO: this.fields.deleteMO,
                    subEmpreitadas: this.fields.deleteSubempreitada
                }
            };
            

            // Verificar se há 1 linha de mao de obra externa/interna
            let arrays_vazios = 0
            if(this.fields.funcionariosInternos.length == 0)
                arrays_vazios++
            if(this.fields.funcionariosExternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosInternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosExternos.length == 0)
                arrays_vazios++

            if(arrays_vazios == 4){
                this.error_message = "Adicione pelo menos 1 equipamento ou mão de obra"
                this.error = true
                this.loading = false;
                this.loader = false;
                return
            }

            // Não podem existir horas de trabalho a zero
            // Vamos a todos os funcionarios
            // Internos e externos
            // Verificar as horas que eles têm registadas (Ho + Hext > 0)
            let existem_horas_a_zero = false;
            this.fields.funcionariosInternos.forEach(element => {
                let res = parseFloat(element.hn) + parseFloat(element.hext)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            this.fields.funcionariosExternos.forEach(element => {
                let res = parseFloat(element.hn) + parseFloat(element.hext)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            if(existem_horas_a_zero){
                this.error_message = 'Os funcionários não podem ter valores de trabalho a zero (Hn + Hext > 0)'
                this.error = true
                this.loader = false
                return
            }

            this.fields.equipamentosInternos.forEach(element => {
                let res = parseFloat(element.hs) + parseFloat(element.ho) + parseFloat(element.ha)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            this.fields.equipamentosExternos.forEach(element => {
                let res = parseFloat(element.hs) + parseFloat(element.ho) + parseFloat(element.ha)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            if(existem_horas_a_zero){
                this.error_message = 'Os equipamentos não podem ter valores de trabalho a zero (Hs + Ho + Ha > 0)'
                this.error = true
                this.loader = false
                return
            }
            

            PDEnc[this.method](data, data.hash).then((resp) => {
                this.success = true;

                this.error = false;

                this.loader = false;

                /*setTimeout(() => {
                    this.$router.push("/diarios/v2");
                }, 2000);*/

                /*if(this.method == 'create'){
                    let link = resp.data
                    this.$router.push("/diarios/encarregado/editar/" + link.hash + "/" + link.creator);
                }else
                    window.location.reload()*/

            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loader = false;
            });
        },
        reloadPage(){
            window.location.reload()
        },
        getFuncionariosPorEntidade(){
            let r = []

            this.funcionariosPorEntidade = []

            this.funcionarios_externos.forEach(element => {
                if(element.entidade != null){
                    if(element.entidade.ID == this.entitySelected){
                        let func = element.Numero + ' - ' + element.Nome
                        
                        let ob = null;
                        if(this.searchEntitySelected == null){
                            ob = {
                                    id: element.ID,
                                    funcionario: func,
                                    hn: 8,
                                    hext: 0, 
                                    local: null,
                                    selected: false,
                                    numero: element.Numero,
                                    entidade: element.entidade
                                }
                        }else{
                            if(func.toLowerCase().includes(this.searchEntitySelected.toLowerCase())){                                
                                ob = {
                                        id: element.ID,
                                        funcionario: func,
                                        hn: 8,
                                        hext: 0, 
                                        local: null,
                                        selected: false,
                                        numero: element.Numero,
                                        entidade: element.entidade
                                    }
                            }
                        }


                        let existe = false
                        if(ob != null){
                            this.fields.funcionariosExternos.forEach(aux => {
                                if(aux.funcionario == ob.numero)
                                    existe = true
                            });
                        }

                        if(!existe)
                            if(ob != null)
                                r.push(ob)
                    }
                }
            });


            this.funcionariosPorEntidade = r
        }        
    },
    computed:{              
        computeFuncionariosExternos(){
            let returner = []

            this.fields.funcionariosExternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.hideFrentes == false){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner
        }, 
        computeFuncionariosInternos(){
            let returner = []

            this.fields.funcionariosInternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.hideFrentes == false){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner
        },
        canSave(){
            // Verificar se há 1 linha de mao de obra externa/interna
            let arrays_vazios = 0
            if(this.fields.funcionariosInternos.length == 0)
                arrays_vazios++
            if(this.fields.funcionariosExternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosInternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosExternos.length == 0)
                arrays_vazios++

            return arrays_vazios
        }, 
        getWindowHeight(){
            //Altura da janela menos 20%

            let calculo = window.innerHeight * 0.45
            
            return window.innerHeight - calculo
        },
    }
}
</script>